import { webapi,ErrorParse } from './webapi';


const StripePayment = async (dataArray) => {

    const toPostArray = dataArray.map((item, index) => ({
        ...item,
        successUrl: window.location.origin + "/#my-profile",
        cancelUrl: window.location.origin + "/#payments/0"
    }));

    try {
        const response = await webapi.post('/api/stripe/create-checkout-sessionv2', JSON.stringify(toPostArray));
        window.location=response.data.url;
    }
    catch (error) {
        ErrorParse(error);
    }

    /*
    await fetch(config.baseUrl + "/api/stripe/create-checkout-sessionv2", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify(toPostArray),


    })
        .then(res => {
            if (res.ok) return res.json()
            return res.json().then(json => Promise.reject(json))
        })
        .then(({ url }) => {
            window.location = url
        })
        .catch(e => {
            console.error(JSON.stringify(e))
        })
        */
};
const PhonePePayments = async (dataArray) => {

    const toPostArray = dataArray.map((item, index) => ({
        ...item,
        successUrl: window.location.origin + "/#my-profile",
        cancelUrl: window.location.origin + "/#payments/0"
    }));

    try {
        const response = await webapi.post('/api/phonepe/create-checkout', JSON.stringify(toPostArray));
        window.location=response.data.url;
    }
    catch (error) {
        ErrorParse(error);
    }

    /*
    console.log("data " + JSON.stringify(toPostArray));
    await fetch(config.baseUrl + "/api/phonepe/create-checkout", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify(toPostArray),
        credentials: 'include'

    })
        .then(res => {
            if (res.ok) return res.json()
            return res.json().then(json => Promise.reject(json))
        })
        .then(({ url }) => {
            window.location = url
        })
        .catch(e => {
            console.error(JSON.stringify(e))
        })
        */
};
const ListOwnedProducts = async (callbacks) => {
    const { onSuccess, onError } = callbacks;
    try {
        const response = await webapi.post('/api/payments/list-owned-products', null);
        onSuccess(response.data);
    }
    catch (error) {
        onError(ErrorParse(error));
    }
}
//#region  SUBSCRIPTIONS!!!!!!
const StripeCreateCustomer = async (callbacks) => {
    const { onSuccess, onError } = callbacks;
    try {
        const response = await webapi.post('/api/stripe/create-customer', null);
        onSuccess(response.data);
    }
    catch (error) {
        onError(ErrorParse(error));
    }
}
const StripeSubscriptionPayment = async (dataArray) => {
    try {

        const toPostArray = dataArray.map((item, index) => ({
            ...item,
            successUrl: window.location.origin + "/#my-profile",
            cancelUrl: window.location.origin + "/#payments/0"
        }));

        const response = await webapi.post('/api/stripe/create-subscription', JSON.stringify(toPostArray));
        window.location= response.data.url;
    }
    catch (error) {
        ErrorParse(error);
    }
}
const StripeCancelSubscription = async (subscriptionId, callbacks) => {
    const { onSuccess, onError } = callbacks;
    try {
        const response = await webapi.post('/api/stripe/cancel-subscription', { subscriptionId: subscriptionId });
        onSuccess(response.data);
    }
    catch (error) {
        onError(ErrorParse(error));
    }
}

/*
const StripeListSubscriptions = async (callbacks) => {
    const { onSuccess, onError } = callbacks;
    try {
        //const response = await api.get('/api/stripe/subscriptions', { withCredentials: true });

        const persistentToken = localStorage.getItem('token');
        const rememberMe = JSON.parse(localStorage.getItem('remember_me'));
        const sessionToken = sessionStorage.getItem('session_token');
        let token;
        const boolRememberMe = !!rememberMe;

        if (boolRememberMe) token = persistentToken;
        else token=sessionToken;


        const subscriptions = await fetch(config.baseUrl + '/api/stripe/subscriptions', { headers: {
            'Authorization': `Bearer ${token}`
          }
         }).then(r => r.json());
        onSuccess(subscriptions);

        //  const response = await fetch(config.baseUrl+'/api/stripe/subscriptions',{ credentials: "include" }).then(r => r);
        //  onSuccess(response);

    }
    catch (error) {
        onError(ErrorParse(error));
    }
}
*/

const StripeRefreshSubscriptionsList = async (callbacks) => {
    const { onSuccess, onError } = callbacks;
    try {
        //const response = await api.get('/api/stripe/subscriptions', { withCredentials: true });

        const subscriptions = await webapi.get('/api/stripe/refresh-subscriptions');
        onSuccess(subscriptions);
        //  const response = await fetch(config.baseUrl+'/api/stripe/subscriptions',{ credentials: "include" }).then(r => r);
        //  onSuccess(response);

    }
    catch (error) {
        onError(ErrorParse(error));
    }
}


//#endregion  SUBSCRIPTIONS STRIPE!!!!!!

//#region  SUBSCRIPTIONS PHONEPE!!!!!!
const PhonePeSubscriptionPayment = async (dataArray) => {
    try {

            const toPostArray = dataArray.map((item, index) => ({
                ...item,
                successUrl: window.location.origin + "/#my-profile",
                cancelUrl: window.location.origin + "/#payments/0"
            }));
    

        const response = await webapi.post('/api/phonepe/create-subscription', JSON.stringify(toPostArray));
        window.location= response.data.url;
    }
    catch (error) {
        ErrorParse(error);
    }
}


const GetPreSignedUrl = async (key,directory,callbacks) => {
    const { onSuccess, onError } = callbacks;

    try {
        const response = await webapi.post('/api/other/get-presigned-url', {key:key, directory:directory});
        onSuccess(response.data);
    }
    catch (error) {
        onError(ErrorParse(error));
    }
}


export { StripePayment, PhonePePayments, ListOwnedProducts };
export { StripeCreateCustomer, StripeSubscriptionPayment, StripeCancelSubscription };
export { PhonePeSubscriptionPayment, StripeRefreshSubscriptionsList };
export {GetPreSignedUrl};