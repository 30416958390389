import { useState, useEffect } from "react";
import BeforeLoginModal from "./BeforeLoginModal";

function SubscriptionCard({ isLoggedIn,title,maintitle, inrPrice, usdPrice, discount,tenure,addToCart,isUSDCurrency, listcontent }) {
  const [showBeforeLoginModal, setShowBeforeLoginModal] = useState(false);

  const handleBeforeLoginModal = () => {
    setShowBeforeLoginModal(true);
  };
  const discountedPrice = inrPrice - (discount / 100) * inrPrice;
  const roundedDiscountedPrice = roundToNearestMultiple(discountedPrice,500);
  
  const usdDiscountPrice = usdPrice - (discount / 100) * usdPrice;
  function roundToNearestMultiple(value, multiple) {
    return Math.round(value / multiple) * multiple;
  }
  const subscribeItem = {
    Type: "Subscribe",
    CoursePrice: isUSDCurrency ? tenure === "Monthly"? usdDiscountPrice
    : usdDiscountPrice * (tenure === "Yearly" ? 12 : 1)
    : tenure=="Monthly"? roundedDiscountedPrice : 
    roundedDiscountedPrice * (tenure === "Yearly" ? 12 : 1),
    Currency: isUSDCurrency ? 'USD' : 'INR',
    CourseId: "SUB"+tenure,
    CourseImageURL100x100: 'https://skillbakery.com/images/subscription.svg',
    CourseTitle:  tenure === "Monthly"?"Monthly Subscription":"Yearly Subscription",
    ExpireInDays:tenure === "Monthly"?30:365
  };

  return (
    <div className="subscription__card">
      <p class="main-title mb-0">{maintitle}</p>
      <div className="subscription__head">
        <div className="price">
          <div className="price__now">
            {!isUSDCurrency ? (
                <span className="india">
                  ₹{tenure === "Monthly"?roundedDiscountedPrice :roundedDiscountedPrice + '/Month'}
                </span>
              ) : (
                <span className="usa">
                  ${tenure === "Monthly"?usdPrice - (discount / 100) * usdPrice: usdPrice - (discount / 100) * usdPrice + '/Month'}
                </span>
              )}
          </div>
          {discount > 0 ? (
            <>
            <div className="d-flex" style={{justifyContent:'center',alignItems:'center', gap:'9px'}}>
              <div className="price__before">
                  <p>
                    {!isUSDCurrency ? (
                      `₹${inrPrice}`
                    ) : (
                      `$${usdPrice}`
                    )}
                  </p>
                </div>
                <p className="discount">{discount}% off</p>
            </div>
            </>
          ) : (
            <>
            <div className="d-flex" style={{justifyContent:'center',alignItems:'center', gap:'9px'}}>
              <div className="price__before" style={{visibility:'hidden'}}>
                <p>
                  {!isUSDCurrency ? (
                    `₹${inrPrice}`
                  ) : (
                    `$${usdPrice}`
                  )}
                </p>
              </div>
              <p className="discount" style={{visibility:'hidden'}}>{discount}% off</p>
              </div>
            </>
          ) } 
        </div>
      </div>
      <h3>{title}</h3>
      {tenure === "Monthly" ? (
        <>
        <div>
          {listcontent}
        </div>
        <div className="buttons-container">
           {isUSDCurrency ? (
                <button className="btn green" aria-label="buy" 
                onClick={() => (isLoggedIn ? addToCart('Subscription',subscribeItem,null) : handleBeforeLoginModal())}>
                  Subscribe Now for a month at ${usdPrice}
                </button>
              ) : (
                <button className="btn mint" 
                onClick={() => (isLoggedIn ? addToCart('Subscription',subscribeItem,null) : handleBeforeLoginModal())}>
                  Subscribe Now for a month ₹{inrPrice}
                </button>
              )
           }
        </div>
        </>
      ) : 
      <>
      <div>
        {listcontent}
      </div>
       <div className="buttons-container">
        
         {isUSDCurrency ? (
            <button className="btn green" aria-label="buy" 
            onClick={() => (isLoggedIn ? addToCart('Subscription',subscribeItem,null) : handleBeforeLoginModal())}>
              Subscribe Now for 12 Months at ${usdDiscountPrice * 12}
            </button>
          ) : (
            <button className="btn mint" 
            onClick={() => (isLoggedIn ? addToCart('Subscription',subscribeItem,null) : handleBeforeLoginModal())}>
              Subscribe Now for 12 Months at ₹{roundedDiscountedPrice * 12}
            </button>
          )
         }
        </div>
        </>
      }
      {showBeforeLoginModal && (
        <BeforeLoginModal handleClose={() => setShowBeforeLoginModal(false)} />
      )}
    </div>
  );
}

export default SubscriptionCard;