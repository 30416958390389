// Alert.js
import React, { Component } from 'react';

class Alert extends Component {

    constructor(props, context) {
      super(props, context);
      this.state = {
        isActive: true,
      }
    }
  
    hideAlert() {
      this.setState({
        isActive: false,
      });
    }
  
    render() {
      if (this.state.isActive) {
        return (
            <div
              className="alert custom alert-warning alert-dismissible"
              role="alert"
            >
              <div className='alerttext'>
              If you have opted for Download, then you can download the video using the download option provided in the video player.
              </div>
              <button
                type="button"
                className="closealert"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => this.hideAlert()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {this.props.text}
            </div>
        );
      }
      return <div/>
    }
  }
  
  export default Alert;
