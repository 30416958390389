import React, { useEffect, useState  } from "react";
import { HashRouter as BrowserRouter, Routes, Route, createRoutesFromElements, createBrowserRouter,useLocation   } from "react-router-dom";
import Homepage from "./pages/Homepage";
import LearningPath from "./pages/LearningPath";
import ConfirmEmail from "./pages/ConfirmEmail";
import PaymentsConfirmation from "./pages/Payments";
import ResetPasswordSuccessPage from "./pages/ResetPassword";
import MyProfile from "./pages/MyProfile";
import Class5Onwards from "./pages/Class5th";
import Class7Onwards from "./pages/Class7th";
import Class10Onwards from "./pages/Class10th";
import CollegeStudents from "./pages/CollegeStudents";
import Professionals from "./pages/Professionals";
import { showFailedToast } from "./components/ToastNotifications";
import confetti from 'canvas-confetti';
import CryptoJS from 'crypto-js';
import TermsConditions from "./pages/TermsConditions";
import CourseDetails from './pages/CourseDetails';
import Faq from "./pages/Faq";

//ReactGA.initialize('362387461');


function App() {
 const SECRET_KEY = 'F97y3#sDvT6@Pq!2';
 const [isLoggedIn, setIsLoggedIn] = useState(false);
 const [username, setUsername] = useState(null);
 /*Check Currency */
 const [isUSDCurrency, setIsUSDCurrency] = useState(true);
 /*Add To Cart */
 const [cartItems, setCartItems] = useState([]);

 const handleConfetti = () => {
    confetti({
    particleCount: 250,
    spread: 120,
    });
  };
 
 const addToCart = (mode, item, discount) => {
  //Check for duplicate CourseID in the cart
  const isDuplicate = cartItems.some((cartItem) => cartItem.ID === item?.CourseId);
  //Check if subscription present then do not allow any other type
  const isSubscriptionPresent = cartItems.some(item => item.Type === 'Subscription');
  const isRentOrDownloadPresent = cartItems.some(item => item.Type === 'Rent' || item.Type === 'Download');

  if (
    (mode === 'Subscription' && (isRentOrDownloadPresent || isSubscriptionPresent)) ||
    ((mode === 'Rent' || mode === 'Download') && isSubscriptionPresent)
  ) {
    // Do not allow adding a new subscription if there's already one in the cart
    showFailedToast('Cannot add another subscription or Rent/Download.','top-center');
    return;
  }

  if (!isDuplicate) {
    const newCartItem = {
      Type: mode,
      Amount:  discount==null?item.CoursePrice:discount,
      Currency: isUSDCurrency ? 'USD' : 'INR',
      ID: item?.CourseId,
      Image: item?.CourseImageURL100x100,
      Title: item?.CourseTitle,
      ExpireInDays:mode=='Subscription'?item.ExpireInDays:365
    };
   
    setCartItems((prevCartItems) => {
      const updatedCartItems = [...prevCartItems, newCartItem];
      // Save the updated cart to localStorage
      saveCartToLocalStorage(updatedCartItems);
      console.log('Item added to cart:', newCartItem);
      return updatedCartItems; // Return the updated value for state
    });

    handleConfetti();
    // const itemsInCart = getCartItems();
    // console.log('Items in Cart:', itemsInCart);
  } else {
    console.log('Item is already in the cart.');
    // const itemsInCart = getCartItems();
    // console.log('Items in Cart:', itemsInCart);
  }
 };
 const cartItemCount = cartItems.length;
 /* Get All Cart Items */
 const getCartItems = () => {
    return cartItems;
 }
 /* Remove Items from Cart */
 const removeFromCart = (itemId) => {
    setCartItems((prevCartItems) => {
      const updatedCartItems = prevCartItems.filter((item) => item.ID !== itemId);
      console.log('Updated Cart Items:', updatedCartItems);
      saveCartToLocalStorage(updatedCartItems);
      return updatedCartItems;
    });
 };
 //empty Cart
const emptyCart = () => {
  setCartItems([]);
  saveCartToLocalStorage([]); // Assuming you want to update local storage as well
};
 /* Persist Items */
const saveCartToLocalStorage = (cartItems) => {
  const serializedCart = JSON.stringify(cartItems);
   // Encrypt serializedCart using AES encryption with secret key
  const encryptedCart = CryptoJS.AES.encrypt(serializedCart, SECRET_KEY).toString();
   // Save encrypted cart to localStorage
   localStorage.setItem('cartItems', encryptedCart);
  //localStorage.setItem('cartItems', serializedCart);
};
const loadCartFromLocalStorage = () => {
  const serializedCart = localStorage.getItem('cartItems');
  if (serializedCart) {
  try {
      const decryptedCart = CryptoJS.AES.decrypt(serializedCart, SECRET_KEY).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedCart);
    } catch (error) {
      console.error('Error parsing decrypted cart:', error);
      // If there's an error parsing decrypted data, return an empty array
      return [];
    }
  } else {
    return [];
  }
};

 const toggleCurrency = () => {
    // Check if there are items in the cart
    if (cartItems.length === 0) {
      setIsUSDCurrency((prevIsUSDCurrency) => {
        // Return the new value for isUSDCurrency
        const newIsUSDCurrency = !prevIsUSDCurrency;
        localStorage.setItem('isUSDCurrency', newIsUSDCurrency.toString());
        return newIsUSDCurrency;
      });
    } else {
      // Cart is not empty, do not allow toggling
      console.log('Cannot toggle currency. Cart is not empty.');
    }
 };

  useEffect(() => {
    //ReactGA.pageview(window.location.pathname + window.location.search);
    window.gtag('config', 'GA_MEASUREMENT_ID', {
      page_path: window.location.pathname,
    });
    const storedCartItems = loadCartFromLocalStorage();
    setCartItems(storedCartItems);

    const storedCurrency = localStorage.getItem('isUSDCurrency');
    if (storedCurrency !== null) {
      setIsUSDCurrency(storedCurrency === 'true');
    }
    const storedAuthState = localStorage.getItem("authState");
    if (storedAuthState) {
      const { loggedIn, expiry } = JSON.parse(storedAuthState);
      // Check if the stored authentication state is not expired
      if (loggedIn && new Date(expiry) > new Date()) {
        setIsLoggedIn(true);
      } else {
        // Clear expired authentication state from local storage
        localStorage.removeItem("authState");
      }
    }
    
  }, []);
  
  console.log("App Login"+isLoggedIn);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:mode?" element={<Homepage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} addToCart={addToCart} removeFromCart={removeFromCart} getCartItems={getCartItems} cartItemCount={cartItemCount} isUSDCurrency={isUSDCurrency} toggleCurrency={toggleCurrency} emptyCart={emptyCart}/>}/>
        <Route path="/confirm-email/:token/:email" element={<ConfirmEmail />} />
        <Route path="/learning-path" element={<LearningPath isLoggedIn={isLoggedIn} addToCart={addToCart} removeFromCart={removeFromCart} getCartItems={getCartItems} cartItemCount={cartItemCount} isUSDCurrency={isUSDCurrency} toggleCurrency={toggleCurrency}/>} />
        <Route path="/reset-password/:token/:email" element={<ResetPasswordSuccessPage />} />
        <Route path="/payments/:status" element={<PaymentsConfirmation />} />
        <Route path="/my-profile" element={<MyProfile addToCart={addToCart} removeFromCart={removeFromCart} getCartItems={getCartItems} cartItemCount={cartItemCount} isUSDCurrency={isUSDCurrency} toggleCurrency={toggleCurrency} emptyCart={emptyCart} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} /> } />
        <Route path="/Class5th" Component={Class5Onwards} />
        <Route path="/Class7th" Component={Class7Onwards} />
        <Route path="/Class10th" Component={Class10Onwards} />
        <Route path="/CollegeStudents" Component={CollegeStudents} />
        <Route path="/Professionals" Component={Professionals} />
		    <Route path="/TermsConditions" Component={TermsConditions} />
        <Route path="/Faq" Component={Faq} />
        <Route path="/course-details/:courseName" element={<CourseDetails isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} addToCart={addToCart} removeFromCart={removeFromCart} getCartItems={getCartItems} cartItemCount={cartItemCount} isUSDCurrency={isUSDCurrency} toggleCurrency={toggleCurrency} emptyCart={emptyCart}/>} /> // Dynamic route for course details
      </Routes>
    </BrowserRouter>
  );
}

export default App;