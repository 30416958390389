function SubscriptionCard({ maintitle, title, inrPrice, usdPrice, discount,tenure,addToCart,isUSDCurrency, listcontent, buttonLabel }) {

  const discountedPrice = inrPrice - (discount / 100) * inrPrice;
  const roundedDiscountedPrice = discountedPrice;//roundToNearestMultiple(discountedPrice,600);
  
  const usdDiscountPrice = usdPrice - (discount / 100) * usdPrice;
  function roundToNearestMultiple(value, multiple) {
    return Math.round(value / multiple) * multiple;
  }
  const subscribeItem = {
    Type: "Subscribe",
    CoursePrice: isUSDCurrency ? tenure === "Monthly"? usdDiscountPrice
    : usdDiscountPrice * (tenure === "Yearly" ? 12 : 1)
    : tenure=="Monthly"? roundedDiscountedPrice : 
    roundedDiscountedPrice * (tenure === "Yearly" ? 12 : 1),
    Currency: isUSDCurrency ? 'USD' : 'INR',
    CourseId: "SUB"+tenure,
    CourseImageURL100x100: 'https://skillbakery.com/images/subscription.svg',
    CourseTitle: title,
  };

  return (
    <div className="subscription__card">
      <p className="main-title mb-0">{maintitle}</p>
      <div className="subscription__head" style={{marginBottom:'43px'}}>
        <div className="price">
        {discount > 0 && (
            <>
              <div className="price__before">
                <p>
                {'\u00a0'}{!isUSDCurrency ? (
                    `₹${inrPrice}`
                  ) : (
                    `$${usdPrice}`
                  )}{'\u00a0'}
                </p>
              </div>
              <p className="discount">Save {discount}%</p>
            </>
          )}
          <div className="price__now">
            {!isUSDCurrency ? (
                <span className="india">
                  ₹{roundedDiscountedPrice}{"/Course"}
                </span>
                  ) : (
                  <span className="usa">
                    ${usdPrice - (discount / 100) * usdPrice + '/Course'}
                  </span>
              )}
          </div>
          
        </div>
      </div>
      <h3>{title}</h3>
      <div>{listcontent}</div>
      <button className="sub-btn">{buttonLabel}</button>
    </div>
    
  );
}

export default SubscriptionCard;