import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faCheck, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import {StripePayment,PhonePePayments, StripeSubscriptionPayment, PhonePeSubscriptionPayment} from "../services/api_payments";

import CartItem from "./CartItem";
import { json } from "react-router-dom";

function CartDetailsModal({ removeFromCart,cartItems, handleClose, show,isUSDCurrency, getCartItems,emptyCart }) {
  
  const OnBuyClicked = async () =>{
    let data=[];
    getCartItems().forEach(element => {
      data.push({
        Id: element.ID,
        ProductType: element.Type === 'Subscription'
        ? 'Subscription'
        : element.Type === 'Download' || element.Type === 'Rent'
        ? 'IndividualCourse'
        : 'BundleCourse',
        Price: element.Amount,
        Image: element.Image,
        Title: element.Title,
        Type: element.Type,
        Currency:element.Currency,
        ExpireInDays:element.ExpireInDays,
        SubscriptionPriceId:"price_1OUnKNSCbgFHXELDZ6zIVCFz"
      });  
    });
    
    if (isUSDCurrency)
    {
      if (data[0]?.ProductType == 'Subscription')
      {
        await StripeSubscriptionPayment(data);
        emptyCart();
      }
      else{
        await StripePayment(data);
        emptyCart();
      }
    }
    else
    {
      if (data[0]?.ProductType == 'Subscription'){
        await PhonePeSubscriptionPayment(data);
        emptyCart();
      }
      else{
        await PhonePePayments(data);
        emptyCart();
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="cartPopup" centered>
      <Modal.Body>
        <button className="btn-close" type="button" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} />
          <i className="fa fa-times"></i>
        </button>
        <div className="modal-body__head">
          <h3>
            <div className="icon">
              <i style={{position:'relative', bottom:'-2px'}}>
                <FontAwesomeIcon icon={faCartShopping} />
              </i>
            </div>
            <span>Cart</span>-<span>{cartItems?.length}</span><span>item(s)</span>
          </h3>
        </div>
        <div className="modal-body__info">
          <div className="cart-header"><span className="c-sign">{isUSDCurrency?'$':'₹'}</span></div>
           {/* Check if cartItems is not null or empty before rendering */}
            {cartItems && cartItems.length > 0 ? (
              cartItems.map((cartItem) => (
                <CartItem key={cartItem.ID} cartItem={cartItem} removeFromCart={removeFromCart}/>
              ))
            ) : (
              <p>Your cart is empty.</p>
            )}
          <div className="cart-footer"><span className="t-amount"> Total {cartItems?.reduce((total, item) => total + item.Amount, 0).toFixed(2)}</span></div>
        </div>
        <div className="modal-body__footer">
          <button className="btn secondary" aria-label="add more" style={{width:'52px', height:'50px', display:'none'}}>
             <i style={{fontSize:'20px'}}>
              <FontAwesomeIcon icon={faPlus} />
            </i>
          </button>
          <div className="buttonsContainer" style={{marginLeft:'auto'}}>
            <button className="btn green" aria-label="buy" style={{height:'50px' }} type="button" onClick={()=>OnBuyClicked()}>
              Buy
            </button>
            {/* <button className="btn mint" aria-label="buy">
              Buy for ₹5.556
            </button> */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CartDetailsModal;
