// Class7OnwardsPage.js
import React, { useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowRight,
  faClockFour,
  faFileVideo,
  faLaptopCode,
  faPlay,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";

const Class7Onwards = (toggleCurrency) => {
    const headerStyle = {
        position: 'relative',
        borderBottom: '1px solid rgba(255, 255, 255, 0.8)',
      };
    
      const getCartItems = () => {
        // Your implementation to get cart items
      };
    
  return (
    <div className="homepage-wrapper">
       {/* <video id="background-video" autoPlay loop muted>
        <source src="./images/giphy-new.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <img src="./images/giphy-new.gif" id="background-video"></img>
      <Header getCartItems={getCartItems}/>
      <main>
        <div className="top-box"></div>
        <section className="pb-115">
            <div className="container">
                <div className="row">
                    <div className="col">
                     <figure className="cfb-box">
                       <img src="./images/class7th-banner.svg"/>
                     </figure>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center section-top-head">
                      <h2 className="section-head">Features</h2>
                      <p className="text-center">Unlock your child's potential with our interactive programming courses</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-12 f-cards">
                      <div>
                        <figure className="mb-0">
                          <img src="./images/fc-1.svg"/>
                        </figure>
                      </div>
                      <div>
                        <h4 className="section-head">Interactive Coding</h4>
                        <p className="mb-0">Make coding fun and engaging exercise for a joyful learning experience.</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 f-cards">
                    <div>
                        <figure className="mb-0">
                          <img src="./images/fc-2.svg"/>
                        </figure>
                      </div>
                      <div>
                        <h4 className="section-head">Step-by-Step Guidance</h4>
                        <p className="mb-0">Clear instructions to help students understand programming concepts.</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 f-cards">
                    <div>
                        <figure className="mb-0">
                          <img src="./images/fc-3.svg"/>
                        </figure>
                      </div>
                      <div>
                        <h4 className="section-head">Real-World Examples</h4>
                        <p className="mb-0">Apply concepts through practical coding examples for effective learning.</p>
                      </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
            <div class="container">
            <div class="row"><div class="col text-center section-top-head"><h2 class="section-head">Programming Milestones</h2><p>Start your coding journey today</p></div></div>
              <div class="row">
                <div class="col">
                  <div class="main-timeline">
                    <div class="timeline">
                      <a href="#" class="timeline-content">
                        <span class="timeline-year">1</span>
                        <div class="content">
                          <h3 class="title">Web Development</h3>
                          <p class="description">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                          </p>
                        </div>
                      </a>
                    </div>
                    <div class="timeline">
                      <a href="#" class="timeline-content">
                        <span class="timeline-year">2</span>
                        <div class="content">
                          <h3 class="title">JavaScript</h3>
                          <p class="description">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                          </p>
                        </div>
                      </a>
                    </div>
                    <div class="timeline">
                      <a href="#" class="timeline-content">
                        <span class="timeline-year">3</span>
                        <div class="timeline-icon">
                          <i class="fa fa-cog" aria-hidden="true"></i>
                        </div>
                        <div class="content">
                          <h3 class="title">Mongo DB</h3>
                          <p class="description">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                          </p>
                        </div>
                      </a>
                    </div>
                    <div class="timeline">
                      <a href="#" class="timeline-content">
                        <span class="timeline-year">4</span>
                        <div class="timeline-icon">
                          <i class="fa fa-heart" aria-hidden="true"></i>
                        </div>
                        <div class="content">
                          <h3 class="title">BlackPearl</h3>
                          <p class="description">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                          </p>
                        </div>
                      </a>
                    </div>
                    <div class="timeline">
                      <a href="#" class="timeline-content">
                        <span class="timeline-year">5</span>
                        <div class="timeline-icon">
                          <i class="fa fa-globe" aria-hidden="true"></i>
                        </div>
                        <div class="content">
                          <h3 class="title">Angular</h3>
                          <p class="description">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                          </p>
                        </div>
                      </a>
                    </div>
                    <div class="timeline">
                      <a href="#" class="timeline-content">
                        <span class="timeline-year">6</span>
                        <div class="timeline-icon">
                          <i class="fa fa-apple" aria-hidden="true"></i>
                        </div>
                        <div class="content">
                          <h3 class="title">Laravel</h3>
                          <p class="description">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                          </p>
                        </div>
                      </a>
                    </div>
                    <div class="timeline">
                      <a href="#" class="timeline-content">
                        <span class="timeline-year">7</span>
                        <div class="timeline-icon">
                          <i class="fa fa-edit" aria-hidden="true"></i>
                        </div>
                        <div class="content">
                          <h3 class="title">Creapure</h3>
                          <p class="description">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                          </p>
                        </div>
                      </a>
                    </div>
                    <div class="timeline">
                      <a href="#" class="timeline-content">
                        <span class="timeline-year">8</span>
                        <div class="timeline-icon">
                          <i class="fa fa-edit" aria-hidden="true"></i>
                        </div>
                        <div class="content">
                          <h3 class="title">Kotlin</h3>
                          <p class="description">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
            </div>
            <div className="container mt-5">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                      <div className="col section-top-head">
                        <h2 className="section-head">Common Questions</h2>
                        <p className="text-center">Here are the some of the most common questions that we get</p>
                      </div>
                  </div>
                </div>
                <div className="col-12" style={{marginTop:'35px'}}>
                  <div className="faq-box">
                    <h4 className="cf-q">What programming languages will be taught in SkillBakery Studio?</h4>
                    <p className="cf-a">We will be teaching computer programming using languages such as Python, Scratch, and Java.</p>

                    <h4 className="cf-q">What is the age range for the class 7th students?</h4>
                    <p className="cf-a">The classes are designed for students in class 7th, typically aged between 13-15 years.</p>
                  
                    <h4 className="cf-q">Are the classes suitable for both CBSE and ICSE board students?</h4>
                    <p className="cf-a">Yes, our classes cater to students from both CBSE and ICSE boards.</p>

                    <h4 className="cf-q">What is the teaching approach at SkillBakery Studio?</h4>
                    <p className="cf-a">We believe in making learning fun and engaging. Our classes incorporate interactive activities, games, and projects to make programming enjoyable for students.</p>

                    <h4 className="cf-q">Are there any prerequisites for joining the classes?</h4>
                    <p className="cf-a mb-0">No prior programming experience is required. Our classes are designed to introduce students to programming from scratch.</p>
                  </div>
                </div>
              </div>
            </div>
        </section>
      </main>
      <Footer/>
    </div>
  );
};

export default Class7Onwards;