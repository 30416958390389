// LazyLoadComponent.js
import React from 'react';
import { useInView } from 'react-intersection-observer';

const LazyLoadComponent = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
  });

  return (
    <div ref={ref}>
      {inView ? children : null}
    </div>
  );
};

export default LazyLoadComponent;
