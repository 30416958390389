import {webapi,ErrorParse,SaveTokens} from './webapi'




const IsAuthenticated = async (callbacks) => {
    const { onSuccess, onError } = callbacks;
    try {
        const responseAuth = await webapi.get('/api/auth/is-authenticated');

        onSuccess(responseAuth.data.isAuthenticated);
    }
    catch (error) {
        onError(ErrorParse(error));
    }
}

const Login = async (userData, callbacks) => {
    const { onSuccess, onError } = callbacks;

    try {
        const response = await webapi.post('/api/auth/login', userData);
        SaveTokens(response.data.token,response.data.refreshToken,JSON.parse(response.data.rememberMe));
        onSuccess(response.data);
    } catch (error) {
        onError(ErrorParse(error), error.response.data);
    }

}

const SignUp = async (userData, callbacks) => {
    const { onSuccess, onError } = callbacks;

    try {
        const response = await webapi.post('/api/auth/register', userData);
        onSuccess(response.data.confirmLink)
    } catch (error) {
        onError(ErrorParse(error));
    }
}



const GetUserInfo = async (callbacks) => {
    const { onSuccess, onError } = callbacks;
    try {
        const response = await webapi.get('/api/auth/user-info');
        onSuccess(response.data);
    } catch (error) {
        onError(ErrorParse(error));
    }
}

const UpdateUserInfo = async (data, callbacks) => {
    const { onSuccess, onError } = callbacks;
    try {
        const response = await webapi.post('/api/auth/update-user-info', data);
        onSuccess(response.data);
    } catch (error) {
        onError(ErrorParse(error));
    }
}

const Logout = async (callbacks) => {
    const { onSuccess, onError } = callbacks;
    try {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('remember_me');
        sessionStorage.removeItem('session_token');
        sessionStorage.removeItem('session_refresh_token');
        
        await webapi.post('/api/auth/logout', null);
        onSuccess();
    } catch (error) {
        onError(ErrorParse(error));
    }
}

const FBLogin = async (data, callbacks) => {
    const { onSuccess, onError } = callbacks;
    try {
        const response = await webapi.post('/api/auth/fb-login', data);

        SaveTokens(response.data.token,response.data.refreshToken,JSON.parse(response.data.rememberMe));

        onSuccess(response.data);
    }
    catch (error) {
        onError(ErrorParse(error));
    }
}

const GLogin = async (data, callbacks) => {
    const { onSuccess, onError } = callbacks;

    try {
        const response = await webapi.post('/api/Auth/google-login', data);
        SaveTokens(response.data.token,response.data.refreshToken,JSON.parse(response.data.rememberMe));
        onSuccess(response.data);
    }
    catch (error) {
        onError(ErrorParse(error));
    }
}

const RequestResetPassword = async (data, callbacks) => {
    const { onSuccess, onError } = callbacks;

    try {
        const response = await webapi.post('/api/Auth/request-reset-password', data);
        onSuccess(response.data);
    }
    catch (error) {
        onError(ErrorParse(error));
    }
}

const ResetPassword = async (data, callbacks) => {
    const { onSuccess, onError } = callbacks;
    try {
        const response = await webapi.post('/api/Auth/reset-password', data);
        onSuccess(response.data);
    }
    catch (error) {
        onError(ErrorParse(error));
    }
}
const ResendEmailApi = async (data, callbacks) => {
    const { onSuccess, onError } = callbacks;
    try {
        const response = await webapi.post('/api/Auth/resend-email', data);
        onSuccess(response.data);
    }
    catch (error) {
        onError(ErrorParse(error));
    }
}
const ConfirmEmailApi = async (data, callbacks) => {
    const { onSuccess, onError } = callbacks;
    try {
        const response = await webapi.post('/api/Auth/confirm-email', data);
        onSuccess(response.data);
    }
    catch (error) {
        onError(ErrorParse(error));
    }
}


const ValidateCaptchaApi = async (data, callbacks) => {
    const { onSuccess, onError } = callbacks;
    try {
        const response = await webapi.get('/api/Auth/validate-captcha', { params: (data) });
        onSuccess(response.data);
    }
    catch (error) {
        onError(ErrorParse(error));
    }
}

export { Login, SignUp, IsAuthenticated, GetUserInfo, UpdateUserInfo, Logout, FBLogin, GLogin, ConfirmEmailApi };
export { RequestResetPassword, ResetPassword, ValidateCaptchaApi, ResendEmailApi };