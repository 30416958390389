import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/main.css";
import App from "./App";

import { ToastContainer } from "react-toastify";
const Root = () => {
  return (
    <React.Fragment>
      <App />
      <ToastContainer 
        style={{ whiteSpace: 'nowrap' }}  
          />
    </React.Fragment>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
