// ProfessionalsPage.js
import React, { useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Testimonials from "../Testimonials";
import Contact from "../Contact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowRight,
  faClockFour,
  faFileVideo,
  faLaptopCode,
  faPlay,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";

const Professionals = (toggleCurrency) => {
    const headerStyle = {
        position: 'relative',
        borderBottom: '1px solid rgba(255, 255, 255, 0.8)',
      };
    
      const getCartItems = () => {
        // Your implementation to get cart items
      };
    
  return (
    <div className="homepage-wrapper">
       {/* <video id="background-video" autoPlay loop muted>
        <source src="./images/giphy-new.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <img src="./images/giphy-new.gif" id="background-video"></img>
      <Header getCartItems={getCartItems}/>
      <main>
        <div className="top-box"></div>
        <section className="pb-115">
            <div className="container">
                <div className="row g-0">
                    <div className="col-lg-7 col-12">
                        <div className="pro-wb">
                          <h4>Skillbakery Studio: <span>Navigating</span> Your Path to  <span>Professional</span> Success</h4>
                          <h6>Elevate Your Expertise Amidst the Dynamic Evolution of the Professional Landscape.</h6>
                          <ul>
                              <li>Tailored Courses for Professionals</li>
                              <li>Stay Ahead in Your Industry</li>
                              <li>Broaden Your Horizons</li>
                              <li>Real-world, Hands-on Learning</li>
                              <li>Career Advancement Tools</li>
                          </ul>
                          <button class="btn primary hideon-mobile px-4" type="button">Explore our courses</button>
                        </div>
                    </div>
                    <div className="col-lg-5 col-12">
                    <figure className="mb-0 profigure">
                        <img src="./images/pro-banner.png" className="proimg"/>
                    </figure>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col text-center section-top-head">
                      <h2 className="section-head pro">Features</h2>
                      <p className="text-center pro">Explore Our Professional Features</p>
                    </div>
                </div>
                <div className="row g-4">
                    <div className="col-lg-4 col-md-4 col-12 professionals">
                        <div className="subscription__card">
                          <div>
                            <figure className="mb-0">
                              <img src="./images/adv-course.svg"/>
                            </figure>
                          </div>
                          <div>
                            <h4 className="section-head pro">Advanced Courses</h4>
                            <p className="mb-0 pro normal">Dive into our array of advanced and specialized courses, crafted to cater to the specific needs of professionals seeking to enhance their skill set. Stay competitive and relevant in your industry with our comprehensive and industry-driven curriculum.</p>
                          </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 professionals">
                      <div className="subscription__card">
                        <div>
                          <figure className="mb-0">
                            <img src="./images/certification.svg"/>
                          </figure>
                        </div>
                        <div>
                          <h4 className="section-head pro">Certification Programs</h4>
                          <p className="mb-0 pro normal">Boost your professional credentials with our certification programs. Our certifications are more than badges – they represent practical skills and knowledge that can be applied directly to your work, providing a tangible edge in your career</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 professionals">
                      <div className="subscription__card">
                        <div>
                          <figure className="mb-0">
                            <img src="./images/industry-insight.svg"/>
                          </figure>
                        </div>
                        <div>
                          <h4 className="section-head pro">Industry Insights</h4>
                          <p className="mb-0 pro normal">Stay informed and ahead of the curve with our industry insights. Access thought-provoking articles, blog posts, and webinars from industry experts. Skillbakery Studio is not just about learning; it's about staying abreast of the latest trends and developments in your field</p>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </section>
      </main>
      <Contact />
      <Footer/>
    </div>
  );
};

export default Professionals;