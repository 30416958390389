import axios from 'axios';

import config from '../_config/config'
import { jwtDecode } from 'jwt-decode';

const webapi = axios.create({
    baseURL: config.baseUrl, // Zmień na odpowiedni adres swojego API
    headers: {
        'Content-Type': 'application/json'
    }
});

webapi.interceptors.request.use(
    async configApi => {
        const persistentToken = localStorage.getItem('token');
        const persistentRefreshToken = localStorage.getItem('refresh_token');
        const rememberMe = JSON.parse(localStorage.getItem('remember_me'));
        let sessionToken = sessionStorage.getItem('session_token');
        let sessionRefreshToken = sessionStorage.getItem('session_refresh_token');
        if (!sessionToken) {
            const storedAuthState = localStorage.getItem("authState");
            if (storedAuthState) {
              const { loggedIn, refreshtoken,expiry,token } = JSON.parse(storedAuthState);
              // Check if the stored authentication state is not expired
              if (loggedIn && new Date(expiry) > new Date()) {
                sessionToken = token;
                sessionRefreshToken = refreshtoken;
              }
          }
        }
        
        let token, refreshToken;

        const boolRememberMe = !!rememberMe;
       // console.log("Tokens " +sessionToken + " : " + persistentToken + " : " +boolRememberMe + "vv ")

        if (boolRememberMe) {
            token = persistentToken;
            refreshToken = persistentRefreshToken;
        }
        else {
            token = sessionToken;
            refreshToken = sessionRefreshToken;
        }

        if (token) {
            let decodedToken;

            try{
                decodedToken = jwtDecode(token);
            }
            catch (e){
                console.log(" jwtDecode Error "+e);
                return configApi;//dont go becoause it would hang
            }

            let currentDate = new Date();
         //   console.log("AAA " +JSON.stringify(decodedToken) + " : " + currentDate + " : " +boolRememberMe + "vv ")
            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime() && refreshToken) {
                //token Expired
                try {
                    const response = await axios.post(config.baseUrl + '/api/auth/refresh-tokens', { RefreshToken: refreshToken });
                    SaveTokens(response.data.token,response.data.refreshToken,rememberMe);
                    token = response.data.token;

                }
                catch (error) {
                    console.log(" jwtDecode Error "+error)
                    return configApi;//return, its needed
                }
            }

        //    console.log("BARER " + token);
            configApi.headers['Authorization'] = `Bearer ${token}`;
        }

        return configApi;
    },
    error => {
        throw error;
        //return Promise.reject(error);
    }
);


const SaveTokens=(token,refreshToken,parsedRememberMe)=>
{
    const boolRememberMe=!!parsedRememberMe;
   // console.log("Tokens " +token + " : " + refreshToken + " : " +boolRememberMe + "v22v ")

    if (boolRememberMe)
    {
        localStorage.setItem('token', token);
        localStorage.setItem('refresh_token', refreshToken);
    }
    else
    {
        sessionStorage.setItem('session_token', token);
        sessionStorage.setItem('session_refresh_token',refreshToken);        
    }
    localStorage.setItem("remember_me",JSON.stringify(boolRememberMe));
}

const ErrorParse = error => {
    let err = null;
    if (error.response?.status === 400 && error.response.data && error.response.data.errors) {
        const validationErrors = error.response.data.errors;
        console.log(JSON.stringify(error.response.data.errors));
        err = validationErrors;
    } else if (error.response) {
        // Just in case, shouldnt be here
        console.log(JSON.stringify(error.response));
        err = 'Response error:' + JSON.stringify(error.response);
    } else if (error.response?.data?.errors) {
        //      console.log("ERR" + JSON.stringify(error));
        console.log("ERRResponse" + JSON.stringify(error.response));
        err = error.response.data.errors;
    }
    return err;
}

export {webapi, ErrorParse,SaveTokens}