// Class5OnwardsPage.js
import React, { useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowRight,
  faClockFour,
  faFileVideo,
  faLaptopCode,
  faPlay,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";

const Class5Onwards = (toggleCurrency) => {

    const [activeTab, setActiveTab] = useState("CBSE");

    const headerStyle = {
        position: 'relative',
        borderBottom: '1px solid rgba(255, 255, 255, 0.8)',
      };
    
      const getCartItems = () => {
        // Your implementation to get cart items
      };

      const handleTabClick = (tabName) => {
        if (tabName === "CBSE" || tabName === "ICSE" || tabName === "International") {
            setActiveTab(tabName);
        }
    };
    
  return (
    <div className="homepage-wrapper">
      {/* <video id="background-video" autoPlay loop muted>
        <source src="./images/giphy-new.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <img src="./images/giphy-new.gif" id="background-video"></img>
      <Header getCartItems={getCartItems}/>
      <main>
        <div className="top-box"></div>
        <section className="pb-115">
            <div className="container">
                <div className="row">
                    <div className="col">
                     <figure className="cfb-box">
                       <img src="./images/class5th-banner.svg"/>
                     </figure>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center section-top-head">
                      <h2 className="section-head">Features</h2>
                      <p className="text-center">Unlock your child's potential with our interactive programming courses</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-12 f-cards">
                      <div>
                        <figure className="mb-0">
                          <img src="./images/fc-1.svg"/>
                        </figure>
                      </div>
                      <div>
                        <h4 className="section-head">Interactive Coding</h4>
                        <p className="mb-0">Make coding fun and engaging exercise for a joyful learning experience.</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 f-cards">
                    <div>
                        <figure className="mb-0">
                          <img src="./images/fc-2.svg"/>
                        </figure>
                      </div>
                      <div>
                        <h4 className="section-head">Step-by-Step Guidance</h4>
                        <p className="mb-0">Clear instructions to help students understand programming concepts.</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 f-cards">
                    <div>
                        <figure className="mb-0">
                          <img src="./images/fc-3.svg"/>
                        </figure>
                      </div>
                      <div>
                        <h4 className="section-head">Real-World Examples</h4>
                        <p className="mb-0">Apply concepts through practical coding examples for effective learning.</p>
                      </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col text-center section-top-head">
                      <h2 className="section-head">Programming Milestones</h2>
                      <p style={{marginBottom:'50px'}}>Start your coding journey today</p>
                    </div>
                </div>
                <div className="row">
                          <div className="col text-center section-top-head">
                              <div className="tab-buttons cftc">
                                  <button
                                      className={activeTab === "CBSE" ? "active" : ""}
                                      onClick={() => setActiveTab("CBSE")}
                                  >
                                      CBSE
                                  </button>
                                  <button
                                      className={activeTab === "ICSE" ? "active" : ""}
                                      onClick={() => setActiveTab("ICSE")}
                                  >
                                      ICSE
                                  </button>
                                  <button
                                      className={activeTab === "International" ? "active" : ""}
                                      onClick={() => setActiveTab("International")}
                                  >
                                      INTERNATIONAL
                                  </button>
                              </div>
                          </div>
                      </div>
                      <div className="row">
                          <div className="col">
                              {/* Content based on active tab */}
                              {activeTab === "CBSE" && (
                                <div className="content">
                                  <div className="row">
                                      <div className="col px-0">
                                        <div className="cfr-box">
                                        <ul className="timeline">
                                            <li>
                                              <section datetime="1985-09">1</section>
                                              <div>
                                                <a href="#">
                                                  <span className="title-cf">Generation of Computer</span>
                                                  <span className="para-cf">Discovering Computers: Fun for Young Minds</span>
                                                </a>
                                              </div>
                                            </li>
                                            <li>
                                              <section datetime="1985-09">2</section>
                                              <div>
                                                <a href="#">
                                                  <span className="title-cf">More on Windows 10</span>
                                                  <span className="para-cf">Windows 10 Explorers: Dive Deeper with Fun</span>
                                                </a>
                                              </div>
                                            </li>
                                            <li>
                                              <section datetime="1985-09">3</section>
                                              <div>
                                                <a href="#">
                                                  <span className="title-cf">Working with tables in MS WORD</span>
                                                  <span className="para-cf">Table Magic in MS Word: Create, Format, Excel</span>
                                                </a>
                                              </div>
                                            </li>
                                            <li>
                                              <section datetime="1985-09">4</section>
                                              <div>
                                                <a href="#">
                                                  <span className="title-cf">Using Mail Merge</span>
                                                  <span className="para-cf">Mail Merge Mastery: Personalize with Precision</span>
                                                </a>
                                              </div>
                                            </li>
                                            <li>
                                              <section datetime="1985-09">5</section>
                                              <div>
                                                <a href="#">
                                                  <span className="title-cf">More in Powerpoint 2016</span>
                                                  <span className="para-cf">PowerPoint 2016 Advanced: Elevate Your Presentations</span>
                                                </a>
                                              </div>
                                            </li>
                                            <li>
                                              <section datetime="1985-09">6</section>
                                              <div>
                                                <a href="#">
                                                  <span className="title-cf">Microsoft EXCEL 2016</span>
                                                  <span className="para-cf">Excel 2016 Essentials: Master Data and Formulas</span>
                                                </a>
                                              </div>
                                            </li>
                                            <li>
                                              <section datetime="1985-09">7</section>
                                              <div>
                                                <a href="#">
                                                  <span className="title-cf">More on Internet</span>
                                                  <span className="para-cf">Internet Explorer: Navigating Safely & Skillfully</span>
                                                </a>
                                              </div>
                                            </li>
                                            <li>
                                              <section datetime="1985-09">8</section>
                                              <div>
                                                <a href="#">
                                                  <span className="title-cf">Evolution of AI</span>
                                                  <span className="para-cf">AI Evolution: From Basics to Breakthroughs</span>
                                                </a>
                                              </div>
                                            </li>
                                            <li>
                                            <section datetime="1985-09">9</section>
                                              <div>
                                                <a href="#">
                                                  <span className="title-cf">Learn Sprite</span>
                                                  <span className="para-cf">Sprite Magic: Create, Animate, Fun Unleashed</span>
                                                </a>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              )}
                              {activeTab === "ICSE" && (
                                  <div className="content">
                                    <div className="blank-box">
                                      <h3>Coming Soon: ICSE Coding Journey</h3>
                                      <h5>Unlocking Coding Skills for ICSE Students</h5>
                                    </div>
                                  </div>
                              )}
                              {activeTab === "International" && (
                                  <div className="content">
                                    <div className="blank-box">
                                      <h3>Upcoming: International Coding Experience</h3>
                                      <h5>Exploring Coding Horizons Globally</h5>
                                    </div>
                                  </div>
                              )}
                          </div>
                      </div>
            </div>
            <div className="container mt-5">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                      <div className="col section-top-head">
                        <h2 className="section-head">Common Questions</h2>
                        <p className="text-center">Here are the some of the most common questions that we get</p>
                      </div>
                  </div>
                </div>
                <div className="col-12" style={{marginTop:'35px'}}>
                  <div className="faq-box">
                    <h4 className="cf-q">What programming languages will be taught in SkillBakery Studio?</h4>
                    <p className="cf-a">We will be teaching computer programming using languages such as Python, Scratch, and Java.</p>

                    <h4 className="cf-q">What is the age range for the class 5th students?</h4>
                    <p className="cf-a">The classes are designed for students in class 5th, typically aged between 10-11 years.</p>
                  
                    <h4 className="cf-q">Are the classes suitable for both CBSE and ICSE board students?</h4>
                    <p className="cf-a">Yes, our classes cater to students from both CBSE and ICSE boards.</p>

                    <h4 className="cf-q">What is the teaching approach at SkillBakery Studio?</h4>
                    <p className="cf-a">We believe in making learning fun and engaging. Our classes incorporate interactive activities, games, and projects to make programming enjoyable for students.</p>

                    <h4 className="cf-q">Are there any prerequisites for joining the classes?</h4>
                    <p className="cf-a mb-0">No prior programming experience is required. Our classes are designed to introduce students to programming from scratch.</p>
                  </div>
                </div>
              </div>
            </div>
        </section>
      </main>
      <Footer/>
    </div>
  );
};

export default Class5Onwards;