// FaqPage.js
import React, { useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Testimonials from "../Testimonials";
import Contact from "../Contact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowRight,
  faClockFour,
  faFileVideo,
  faLaptopCode,
  faPlay,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";

const Faq = (toggleCurrency) => {

    const [expandedIndex, setExpandedIndex] = useState(0);

    const headerStyle = {
        position: 'relative',
        borderBottom: '1px solid rgba(255, 255, 255, 0.8)',
      };
    
      const getCartItems = () => {
        // Your implementation to get cart items
      };

      // Toggle function to expand/collapse questions
        const toggleExpand = (index) => {
            setExpandedIndex(index === expandedIndex ? -1 : index);
        };

        // FAQ data (sample data for demonstration)
        const faqData = [
            {
            question: "1. Where are my courses - Download and Rentals?",
            answer: "To access your downloaded courses or rentals, simply log in to your account and navigate to the [My Courses] or [My Library] section. There, you can find your purchased courses, downloads, and rentals.",
            },
            {
            question: "2. How do I download videos?",
            answer: " <div style='display:flex;'><img src='./images/download-button.jpeg' style='width:300px;margin-right:13px;border-radius:6px;overflow:hidden;' /> <p>Downloading videos is easy! Just go to the course or rental page, find the video you want to download, and look for the download button next to it. Click on it, and the video will be saved to your device for offline viewing.</p></div>",
            },
            {
            question: "3. Is there any refund?",
            answer: "We do not offer any refunds. Please refer <a href='https://skillbakery.com/return.html'>Refund Policy</a> for more information.",
            },
            {
            question: "4. Where can I get the source code for courses?",
            answer: "For access to course-related source code, visit <a href='https://github.com/skillbakery/Courses' target='_blank'>Github Repo</a> and download the source code for course that you have opted for.",
            }
            // Add more FAQ items as needed
        ];
    
  return (
    <div className="homepage-wrapper">
       {/* <video id="background-video" autoPlay loop muted>
        <source src="./images/giphy-new.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <img src="./images/giphy-new.gif" id="background-video"></img>
      <Header getCartItems={getCartItems}/>
      <main>
        <div className="top-box"></div>
        <section className="pb-115">
            <div className="container">
                <div className="row g-0">
                    <div className="col-lg-12 col-12">
                        <figure className="mb-0 profigure">
                            <img src="./images/faq-banner.png" className="proimg only"/>
                        </figure>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col text-center">
                    {/* add frequently question here */}
                    <div className=" faqbox">
                        <h3 className="faq-title">FAQs</h3>
                        {faqData.map((item, index) => (
                            <div key={index} className="faq-item">
                                <div
                                className={`faq-question ${expandedIndex === index ? 'expanded' : ''}`}
                                onClick={() => toggleExpand(index)}
                                >
                                {item.question}
                                <span className="toggle-icon">{expandedIndex === index ? '-' : '+'}</span>
                                </div>
                                {expandedIndex === index && (
                                <div className="faq-answer" dangerouslySetInnerHTML={{ __html: item.answer }}></div>
                                )}
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </main>
      <Contact />
      <Footer/>
    </div>
  );
};

export default Faq;