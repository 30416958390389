import React,{useState} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faEnvelope,
  faMessage,
  faQuoteLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const BeforeLoginModal = ({ handleClose }) => {
  const handleLogin = () => {
    // Trigger the click event on .user elements
    const users = document.getElementsByClassName("user");
    Array.from(users).forEach(user => {
      user.click();
    });
    handleClose();
    // const modals = document.getElementsByClassName("btn-close");
    //   if(modals){
    //     Array.from(modals).forEach(modal => {
    //     modal.click();
    //   });
    // }
  }
  const handleSignup = () => {
    const users = document.getElementsByClassName("hideon-mobile");
    Array.from(users).forEach(user => {
      user.click();
    });
    handleClose();
    // const modals = document.getElementsByClassName("btn-close");
    //   if(modals){
    //     Array.from(modals).forEach(modal => {
    //     modal.click();
    //   });
    // }
  }
  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Body>
      <button className="btn-close" type="button" onClick={handleClose}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className="modal-body-head">
        <img
          width="100"
          height="80"
          src="./images/gift-icon.svg"
          alt="Benefits"
        />
        <h3 class="mt-4 mb-3">Unlock Exclusive Benefits!</h3>
        <p className="mb-4">We noticed you're adding an amazing product to your cart without being logged in. Please Login or Signup by closing this dialog</p>
        <div className='lsbtnbx'>
        <button
          className="btn primary small"
          type="button"
          onClick={handleLogin}
        >
          Login
        </button>
        <span>|</span>
        <button
          className="details-btn small"
          type="button"
          onClick={handleSignup}
        >
          Sign up
        </button>
        </div>
      </div>
       </Modal.Body>
    </Modal>
  );
};

export default BeforeLoginModal;

