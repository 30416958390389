
import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { useNavigate, useParams } from "react-router-dom";
import {ConfirmEmailApi} from "../../services/api_auth"

const ConfirmEmail = () => {
  const [selectedSection, setSelectedSection] = useState(null);
  const [successText, setSuccessText]= useState(null);
  const [errorText, setErrorText]= useState(null);
  const {token,email} =useParams();
  const navigate = useNavigate();

  useEffect(()=>
  {
    confirmEmail();
  },[]);

  const confirmEmail = async () => {

    if (token && token.trim() !== ''
      && email && email.trim() !=='')
     {
      let data = {
        email:email,
        token:token
      }
      await ConfirmEmailApi(data,{
        onSuccess: response=> {
            setSuccessText(response.message);
            setTimeout(()=>navigate("/"),2400);
        },
        onError: e=> {
          console.log("ConfirmEmail error " + e);
          setErrorText(e);
        }
      });
     }
     else {
        setErrorText("Bad token or email");
     }
  };


  const renderSections = () => {
    return (
      <div className="container">
        <h2 className="section-head">Email Confirming:</h2>
        {successText && <h2 className="text-center" style={{color:"Chartreuse"}}>{successText}</h2>}
        {errorText && <h2 className="text-center" style={{color:"red"}}>{errorText}</h2>}
        
      </div>
    );
};

  return (
    <div className="homepage-wrapper">
       {/* <video id="background-video" autoPlay loop muted>
        <source src="./images/giphy-new.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <img src="./images/giphy-new.gif" id="background-video"></img>
        <div className="top-box"></div>
        <section className="">
         {renderSections()}
        </section>
    </div>
  );
};

export default ConfirmEmail;

