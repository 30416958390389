import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState, useEffect } from "react";
import axios from 'axios';
import { Login, FBLogin, GLogin, RequestResetPassword, ValidateCaptchaApi, ResendEmailApi } from "../services/api_auth"
import FacebookLogin from '@greatsumini/react-facebook-login';
import LoginGoogle from "./LoginGoogle";
import { googleLogout, GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import {
  faCartShopping,
  faEnvelope,
  faMessage,
  faQuoteLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, Tabs, Tab, Button } from "react-bootstrap";
import StarRatingBasic from "./StarRatingBasic";
import { showSuccessToast, showFailedToast } from "./ToastNotifications";
import { useNavigate, useParams } from "react-router-dom";
import config from "../_config/config";
import ReCAPTCHA from "react-google-recaptcha";

function ResetPasswordModal({ handleClose, show, redirectToLogin }) {
  const [resetInitiated, setResetInitiated] = useState(false);
  const [errorText, setErrorText] = useState("");
  const emailRef = useRef(null);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleResetInitiate = async () => {
    setErrorText("");
    await RequestResetPassword({ email: emailRef.current.value, redirectUrl: window.location.origin+"/" }, {
      onSuccess: response => {
        console.log("ResetLink: " + response.resetLink);
        setResetInitiated(true);
      },
      onError: e => setErrorText(e)
    });
  };

  const onClose = () => {
    redirectToLogin();
    handleClose();
  };

  const onOkayClick = () => {
    onClose();
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);

    // Validate email format using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setErrorText(emailRegex.test(value) ? '' : 'Invalid email format');
  };
  const isEmailValid = email.trim() !== '' && errorText === '';
  const isButtonDisabled = !isEmailValid;

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Body>
        {resetInitiated ? (
          <div className="reset-frm modal-body__banner">
            <img
              width="100"
              height="100"
              src="./images/sent-mail-icon.svg"
              alt="signin"
            />
            <h3 class="mt-4 mb-3">Good news!</h3>
            <p className="mb-4">We've just sent a password reset link to your email. Open it up and follow the simple steps to reset your password.</p>
            <Button className="reset-btn mt-4" type="button" variant="secondary" onClick={onOkayClick}>
              Okay
            </Button>
          </div>
        ) : (
          <div>
            <div className="modal-body__banner">
              <img
                width="100"
                height="100"
                src="./images/svg/bxs_user.svg"
                alt="signin"
              />
              <h3>Forgot password?</h3>
              <p>No worries, we'll send you reset instructions.</p>
            </div>
            <form>
              <div className="inputsContainer">
                <div className="group-control my-4">
                  <input
                    ref={emailRef}
                    className="form-control"
                    type="email"
                    autoComplete="off"
                    placeholder="E-mail"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  {errorText && <p className="error-text" style={{ color: 'red', whiteSpace: 'pre-line' }}>{errorText}</p>}
                </div>
              </div>
              <Button className="btn w-100 primary" variant="primary" onClick={handleResetInitiate} disabled={isButtonDisabled}>
                Reset Password
              </Button>
              <div className="back-to-login text-center mt-4">
                <p>Back to <span onClick={onClose}>Login</span></p>
              </div>
            </form>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}



function SignInModal({ handleClose, show, handleCreateButtonClicked }) {
  const [email, setEmail] = useState("");
  const [emailToConfirmLink, setEmailToConfirmLink] = useState(null);
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const [captchaResolved, setCaptchaResolved] = useState(false);
  const formattedError = error?.replace(/<br>/g, "\n");
  const {mode} =useParams();

  useEffect(() => {
    setEmailToConfirmLink(null);
  }, [error]);

  async function onSubmitClick() {
    let userData =
    {
      Password: password,
      Email: email,
      RememberMe: rememberMe,
    }
    if (captchaResolved) {
      await Login(userData, {
        onSuccess: (data) => {
          showSuccessToast(data.message, "top-center");
          setError("");
          handleClose(data.email, data.username);
        },
        onError: (error, data) => {
          console.log(JSON.stringify(data));
          if (data.emailNotConfirmed)
            setEmailToConfirmLink(email);
          else
            setError(error);
        }
      });
    } else setError("Captcha not resolved");
  }

  const handleResetPasswordClicked = () => {
    // Set resetPassword state to true to show the reset password modal
    setResetPassword(true);
  };

  const redirectToLogin = () => {
    // Reset the resetPassword state when closing the reset password modal
    setResetPassword(false);
  };

  function onClose() {
    setError("");
    redirectToLogin();
    handleClose();
  }


  const handleFacebookLogin = async (response) => {
    await FBLogin(
      {
        AccessToken: response.accessToken,
        RememberMe : rememberMe
      }, {
      onSuccess: data => {
        //console.log("asdasd" + data.email + " : " + data.username + " : " + data.message + "::" + JSON.stringify(data));

        showSuccessToast(data.message, "top-center");
        setError("");
        handleClose(data.email, data.username);
      },
      onError: e => console.log("errr " + e)
    })
  };

  const handleFacebookFail = (error) => {
    setError("Login Failed!: " + JSON.stringify(error))
  };

  const handleGoogleLoginNeedValidation = async (code) => {
    let requestData = {
      Code: code,
      RememberMe:rememberMe,
      RedirectUrl: window.location.origin
    };
    
    await GLogin(requestData, {
      onSuccess: (data) => {
        showSuccessToast(data.message, "top-center");
        setError("");
        handleClose(data.email, data.username);
        console.log(data.email + "::" + data.username);
      },
      onError: (e) => handleGoogleFail(e)
    });
  };

  const handleGoogleFail = (error) => {
    if (error.error == "access_denied")
    setError("Login Failed!: access denied");
    else 
    setError("Login Failed!: " + JSON.stringify(error))
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);

    // Validate email format using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setError(emailRegex.test(value) ? '' : 'Invalid email format');
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError('');
  };
  const handleRecaptcha = async (value) => {
    // Handle the reCAPTCHA value

    await ValidateCaptchaApi({ captchaValue: value }, {
      onSuccess: data => {
        setCaptchaResolved(true);
        console.log("reCAPTCHA resolved:");
      },
      onError: e => { setCaptchaResolved(false); console.log("err " + JSON.stringify(e)); }
    })
  };

  const handleResendClick = async () => {
    await ResendEmailApi({ email: emailToConfirmLink, redirectUrl: window.location.origin+"/" }, {
      onSuccess: response => {
        setError("Email resend successful! Check your emails, including the spam folder, for the confirmation email.");
      },
      onError: e => setError(e)
    });
  };

  const isEmailValid = email.trim() !== '' && error === '';
  const isButtonDisabled = !(isEmailValid && password.trim());

  return (
    <>
      {resetPassword ? (
        <ResetPasswordModal
          handleClose={onClose}
          show={true}
          redirectToLogin={redirectToLogin}
        />
      ) : (

        <Modal show={show} onHide={onClose} centered>
          <Modal.Body>
            <button className="btn-close" type="button" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="modal-body__banner">
              <img
                width="100"
                height="100"
                src="./images/svg/bxs_user.svg"
                alt="signin"
              />

              <h3>Welcome back</h3>
              <p>Provide your login credentials</p>
            </div>
            <form className="modal-body__form" onSubmit={(e) => { e.preventDefault(); onSubmitClick(); }}>
              <div className="inputsContainer">
                <div className="group-control">
                  <input
                    className="form-control"
                    type="email"
                    autoComplete="off"
                    placeholder="E-mail"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="group-control mb-0">
                  <input
                    className="form-control"
                    type="password"
                    autoComplete="off"
                    placeholder="Password"
                    onChange={handlePasswordChange}
                  />
                </div>
               <div className="forget mb-0 mt-0">
                  <div className="form-check fit">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="remember"
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="remember">
                      Remember me
                    </label>
                  </div>
                  <div>
                    <span>
                      <button
                        type="button"
                        className="fgt-btn"
                        onClick={handleResetPasswordClicked}
                      >
                        Forgot password?
                      </button>
                    </span>
                  </div>
              </div>
              <div className="captchabox">
                <ReCAPTCHA
                  sitekey="6Lej67wZAAAAACKFR3pEzuWazzFZgvi8ZlBigfhz"
                  onChange={handleRecaptcha}
                />
              </div>
              </div>
              {formattedError && !emailToConfirmLink && <p className="error-text" style={{ color: 'red', whiteSpace: 'pre-line' }}>{formattedError}</p>}
              {emailToConfirmLink && (
                <div>
                  <p className="error-text" style={{ color: 'red', whiteSpace: 'pre-line' }}>
                    Email not confirmed. Please check your email spam folder and confirm email.
                    Don't see the email? <a href="#" onClick={handleResendClick}>Click here to resend.</a>
                  </p>
                </div>
              )}
              <div className="buttonsContainer">
                <button className="btn w-100 primary" type="submit" aria-label="submit" disabled={isButtonDisabled}>
                  Log in
                </button>
              </div>
              {mode !== "app" && (
                <>
                  <div className="sl-title">or login with</div>
                  <div className="buttonsContainer dflex-row">
                    <GoogleOAuthProvider clientId={config.googlePublicClientId}>
                      <LoginGoogle onFinish={data => handleGoogleLoginNeedValidation(data)} onError={e => handleGoogleFail(e)} />
                    </GoogleOAuthProvider>
                    <FacebookLogin
                      appId={config.facebookApiKey}
                      render={renderProps => (
                        <button onClick={renderProps.onClick} className="btn w-50 facebook" type="button" aria-label="Facebook">
                          <img
                            src="./images/facebook-icon.svg"
                            style={{ marginRight: '3px', width: '21px' }}
                          />
                          Facebook
                        </button>)
                      }
                      onSuccess={async (response) => { handleFacebookLogin(response); }}
                      onFail={(error) => handleFacebookFail(error)}
                    />
                  </div>
                </>
                )}
              <button
                className="sup-btn"
                type="button"
                data-bs-target="#registerModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                aria-label="open modal"
                onClick={handleCreateButtonClicked}
              >
               Don't have an account yet ? <span>Sign up</span>
              </button>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default SignInModal;
