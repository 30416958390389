import { useEffect, useState, Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import jsonData from "../../_library/courses.json";
import FeatureCourseCard from "../../components/FeatureCourseCard";
import DynamicWidthDiv from "../../components/ResizableDiv";

import { Scrollbars } from "react-custom-scrollbars";

export default function OurCourses({ isLoggedIn,addToCart,isUSDCurrency }) {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(-1);
  const [swiperWidth, setSwiperWidth] = useState(0);

  useEffect(() => {
    getData();
    // Update Swiper width on window resize
    window.addEventListener("resize", handleResize);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const categoriesNames = [];
    jsonData &&
      jsonData.CoursesCollection &&
      jsonData.CoursesCollection.forEach((element) => {
        if (!categoriesNames.includes(element.CourseCategory)) {
          categoriesNames.push(element.CourseCategory);
        }
      });
    setCategories(categoriesNames);
  }, [data]);

  const getData = async () => {
    const data = (await jsonData) && jsonData.CoursesCollection;
    // Check if data exists and is an array
    if (Array.isArray(data)) {
      // Sort data array in descending order based on CourseId
      data.sort((a, b) => b.CourseId - a.CourseId);
    }
    setData(data);
  };

  const handleResize = () => {
    const swiperContainer = document.getElementById("swiperContainer");
    setSwiperWidth(swiperContainer.offsetWidth);
  };

  const [swiperClass, setSwiperClass] = useState('');
  const handleWidthChange = (width) => {
    // Update swiperClass based on the width
    setSwiperClass(width < 1917 ? 'lessthan1917' : '');
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const contentHeight =
  activeTab === -1 ? (swiperWidth < 2000 ? '800px' : 'auto') : 'auto';




  return (
    <section className="pb-115" id="ourcourse">
      <div className="container">
        <h2 className="section-head">Featured Courses</h2>
      </div>

      <DynamicWidthDiv onWidthChange={handleWidthChange} />

      <Swiper
        slidesPerView={"auto"}
        spaceBetween={30}
        className={`swipperTab controls col-11 custom-scrollbar`}
        id="swiperContainer"
        style={{overflowX:'auto'}}
      >
        <SwiperSlide className="d-inline-block w-auto">
          <span
            className={`item control cursor-pointer ${
              activeTab === -1 ? "active" : ""
            }`}
            onClick={() => setActiveTab(-1)}
          >
            All
          </span>
        </SwiperSlide>
        {categories &&
          categories.map((item, index) => (
            <SwiperSlide
              className="d-inline-block w-auto"
              key={`${item}${index}`}
            >
              <span
                className={`item control cursor-pointer ${
                  activeTab === index ? "active" : ""
                }`}
                onClick={() => setActiveTab(index)}
              >
                {item}
              </span>
            </SwiperSlide>
          ))}
      </Swiper>
      <div className={`container cardsbox ${swiperWidth < 1000 ? 'w100' : ''}`}>
       {/* all categories  */}
       <Scrollbars
            style={{ height: contentHeight }}
            autoHide={'ture'}
            autoHideTimeout={1000}
            autoHideDuration={200}
            renderThumbVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  backgroundColor: '#6B6B6B',
                  borderRadius: '3px',
                }}
              />
            )}
          >
            
          <div className={`row g-4 feature-list`}>
            {data &&
              data.map((item, index) => (
                <FeatureCourseCard
                  key={index}
                  activeTab={activeTab === -1}
                  data={item}
                  isUSDCurrency={isUSDCurrency}
                  addToCart={addToCart}
                  isLoggedIn={isLoggedIn}
                />
              ))}
          </div>
        </Scrollbars>
      </div>

      {/* other filtered categories */}
      <div className="container">
        {categories &&
          categories.map((item, index) => (
            <Fragment key={`CategoriesMapKey${index}`}>
              {activeTab === index ? (
                <div className={`row g-4 feature-list`} key={index}>
                  {data &&
                    data
                      .filter(
                        (categoryItem) =>
                          categoryItem?.CourseCategory === item
                      )
                      .map((item, index) => (
                        <FeatureCourseCard
                          key={index}
                          activeTab={activeTab === index}
                          data={item}
                          isUSDCurrency={isUSDCurrency}
                          addToCart={addToCart}
                          isLoggedIn={isLoggedIn}
                        />
                      ))}
                </div>
              ) : null}
            </Fragment>
          ))}
      </div>
    </section>
  );
}
