
import { useEffect, useState, Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import jsonData from "../../_library/courses.json";
import MyCourseCard from "../../components/MyCourseCard";
import DynamicWidthDiv from "../../components/ResizableDiv";

import {ListOwnedProducts,StripePayment,GetPreSignedUrl} from "../../services/api_payments";
import { IsAuthenticated, GetUserInfo, Logout, UpdateUserInfo } from "../../services/api_auth";

import { Scrollbars } from "react-custom-scrollbars";

export default function MyCourses({ isLoggedIn,addToCart,isUSDCurrency }) {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(-1);
  const [swiperWidth, setSwiperWidth] = useState(0);
  const [products,setProducts] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getData();
    // Update Swiper width on window resize
    window.addEventListener("resize", handleResize);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const categoriesNames = [];
    jsonData &&
      jsonData.CoursesCollection &&
      jsonData.CoursesCollection.forEach((element) => {
        if (!categoriesNames.includes(element.CourseCategory)) {
          categoriesNames.push(element.CourseCategory);
        }
      });
    setCategories(categoriesNames);
  }, [data]);
  
  useEffect(()=> {
    const GetUserInfoIfAuthenticated = async () => {
      await GetUserInfo({
        onSuccess: (data) => {
          AcquireOwnedProducts();
        },
        onError: e => console.log("GetUserInfo err: " + e)
      });
    }
    GetUserInfoIfAuthenticated();
  },[])

  useEffect(() => {
    filterData();
  }, [data, products]);

  const filterData = () => {
    if (!products || !data.length) {
      setFilteredData([]);
      return;
    }

    // Filter data collection based on the courseId in products collection
    const filtered = data.filter(item => {
      // Accessing the list of products from the single item in products array
      const productList = products[0];
      // Checking if any productId in the productList matches the CourseId of the current item
      return productList.some(product => String(product.productId) === String(item.CourseId));
    });
    setFilteredData(filtered);
  };

  const getData = async () => {
    const data = (await jsonData) && jsonData.CoursesCollection;
    // Check if data exists and is an array
    if (Array.isArray(data)) {
      // Sort data array in descending order based on CourseId
      data.sort((a, b) => b.CourseId - a.CourseId);
    }
    setData(data);
  };

  const handleResize = () => {
    const swiperContainer = document.getElementById("swiperContainer");
    setSwiperWidth(swiperContainer.offsetWidth);
  };

  const [swiperClass, setSwiperClass] = useState('');
  const handleWidthChange = (width) => {
    // Update swiperClass based on the width
    setSwiperClass(width < 1917 ? 'lessthan1917' : '');
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const isSubscriptionExpired = (expirationDate) => {
    const currentDate = new Date();
    const expirationDateObj = new Date(expirationDate * 1000); // Convert from seconds to milliseconds
  
    return expirationDateObj < currentDate;
  };
  async function AcquireOwnedProducts() {
    await ListOwnedProducts({
      onSuccess: async (paymentData) => {
        // Sort the paymentData array based on paymentDate in descending order
        const sortedPaymentData = paymentData.slice().sort((a, b) => b.paymentDate - a.paymentDate);
        const nonExpiredSubscription = sortedPaymentData.filter(product => 
          (product.type === "Download" || product.type === "Rent") && !isSubscriptionExpired(product.expirationDate)
        );
        //setProducts(nonExpiredSubscription ? [nonExpiredSubscription] : null);
        setProducts(nonExpiredSubscription && nonExpiredSubscription.length > 0 ? [nonExpiredSubscription] : null);

        //console.log("Products " + JSON.stringify(nonExpiredSubscription));
      },
      onError: e => console.log("AcquireOwnedProducts err: " + e)
    });
  }
 
// Render the component only if the item is in the products collection
if (!products) {
  return null;
}
  const contentHeight =
  activeTab === -1 ? (swiperWidth < 2000 ? '525px' : 'auto') : 'auto';
  return (
    <section className="pb-115" id="mycourse">
      <div className={`container cardsbox ${swiperWidth < 1000 ? 'w100' : ''}`}>
      {data !== null && (
        <>
        <div className="container">
          <h2 className="section-head">My Courses</h2>
        </div>
        <DynamicWidthDiv onWidthChange={handleWidthChange} />
        <Scrollbars
              style={{ height: contentHeight }}
              autoHide={'ture'}
              autoHideTimeout={1000}
              autoHideDuration={200}
              renderThumbVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    backgroundColor: '#6B6B6B',
                    borderRadius: '3px',
                  }}
                />
              )}
            >
            <div className={`row g-4 feature-list`}>
            {filteredData &&
              filteredData
                .map((item, index) => (
                  <MyCourseCard
                    key={index}
                    activeTab={activeTab === -1}
                    data={item}
                    isUSDCurrency={isUSDCurrency}
                    addToCart={addToCart}
                    isLoggedIn={isLoggedIn}
                  />
                ))
            }
            </div>
          </Scrollbars>
          </>
      )}
      </div>
    </section>
  );
}
