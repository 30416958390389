import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import jsonData from "../../_library/courses.json";
import BannerSliderCard from "../../components/BannerSliderCard";
import BannerSliderThumb from "../../components/BannerSliderThumb";

const Banner = ({addToCart,isUSDCurrency}) => {
  let timer;
  const baseUrl =
    "https://s3.amazonaws.com/static.neostack.com/img/react-slick";
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);
  const elementRef = useRef(null);
  const [data, setData] = useState([]);

  const [isVideoPlaying, setIsVideoPlaying] = useState(true);

  const handleDetailsButtonClick = () => {
    console.log("Calling video pause banner");
    setIsVideoPlaying(false); // Pause the video
  };

  useEffect(() => {
    getData();
  }, []);

  // Shuffle function to randomize array elements
  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const getData = async () => {
    const data = (await jsonData) && jsonData.CoursesCollection;
    // Your original data
    const originalData = data;
    // Shuffle the array
    const shuffledData = shuffleArray(originalData);
    // Take the first 5 elements
    const randomFiveCourses = shuffledData.slice(0, 5);
    setData(randomFiveCourses);
    //setData(data && data.slice(0, 5));
  };

  const onMouseEnterHandler = (event, id) => {
    event.currentTarget.click();

    timer = setTimeout(() => {
      setHovered(true);
      setIsVideoPlaying(true);
      setHoveredId(id);
    }, 3000);

    scrollToCard(event.currentTarget);
  };

  const onMouseRemoveHandler = () => {
    clearInterval(timer);
    setHovered(false);
    setIsVideoPlaying(false);
    setHoveredId(null);
  };

  const scrollToCard = (e) => {
    const windowWidth = window.innerWidth;
    const cardRect = e.getBoundingClientRect();
    const container = document.getElementsByClassName("slick-dots")[0];

    if (cardRect.x < 100) {
      container.scrollBy({ left: -cardRect.width, behavior: "smooth" });
    }

    if (windowWidth < cardRect.right) {
      container.scrollBy({
        top: 0,
        left: cardRect.right - windowWidth + 100,
        behavior: "smooth",
      });
    }
  };

  const settings = {
    customPaging: function (i) {
      return (
        <div
          onMouseEnter={(e) => onMouseEnterHandler(e, data[i].CourseId)}
          onMouseLeave={() => onMouseRemoveHandler()}
        >
          <BannerSliderThumb
            item={data[i]}
            key={`Bannerthumb${data[i].CourseId}`}
            isUSDCurrency={isUSDCurrency}
            addToCart={addToCart}
            onDetailsButtonClick={handleDetailsButtonClick}
          />
        </div>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className="banner-section">
      <h2>Custom Paging</h2>
      <Slider {...settings}>
        {data &&
          data.map((item) => (
            <div key={item?.CourseId}>
              <BannerSliderCard
                item={item}
                hovered={hovered}
                hoveredId={hoveredId}
                isVideoPlaying={isVideoPlaying}
              />
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default Banner;
