import React, { useEffect } from "react";
import { googleLogout, GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import config from '../_config/config';
import { GLogin } from "../services/api_auth";

function LoginGoogle({ onFinish, onError }) {


    const login = useGoogleLogin({
        
        onSuccess:  codeResponse => {
            onFinish(codeResponse.code);
        },
        onError: e => onError(e),
        flow: 'auth-code',
    });
   
    return (
      <GoogleOAuthProvider clientId="718567103018-ua8j4o9d112rp25ftpvb1gg69efsn6av.apps.googleusercontent.com">

            <button className="btn w-50 google" type="button" onClick={e => { login(); }} aria-label="Google">
              <img
                src="./images/google-icon.svg"
                alt="Google"
                style={{ marginRight: '0px', width: '33px' }}
              />
              Google
            </button>
        </GoogleOAuthProvider>
    );

}

export default LoginGoogle;
