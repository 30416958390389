import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faQuoteLeft,
  faChevronRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, Tabs, Tab, Collapse } from "react-bootstrap";
import StarRatingBasic from "./StarRatingBasic";
import React, { useState, useEffect } from "react";
import config from "../_config/config"
import LoginGoogle from "./LoginGoogle";
import { showSuccessToast } from "./ToastNotifications"
import { SignUp, ValidateCaptchaApi } from "../services/api_auth";
import { Login, FBLogin, GLogin, RequestResetPassword } from "../services/api_auth"
import FacebookLogin from '@greatsumini/react-facebook-login';

import { googleLogout, GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate, useParams } from "react-router-dom";

function SignUpModal({ handleClose, show, handleLoginButtonClicked }) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLast] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const formattedError = error.replace(/<br>/g, "\n");
  const [captchaResolved, setCaptchaResolved] = useState(false);
  const {mode} =useParams();
  async function onSubmitClick() {
    let userData =
    {
      Name: name,
      LastName: lastName,
      Address: address,
      PhoneNumber: phoneNumber,
      City: city,
      State: state,
      Country: country,
      Password: password,
      Email: email,
      RedirectUrl: window.location.origin+"/"
    }
    setError("");
    if (!termsAccepted) {
      setError("Please accept terms of services");
    }
    else if (!captchaResolved) {
      setError("Please resolve captcha");
    }
    else {

    await SignUp(userData, {
      onSuccess: (confirmLink) => {
        showSuccessToast("Account created: Please confirm email", "top-center");
        console.log("Created: Please confirm email " + confirmLink);
        onClose();
      },
      onError: (err) => setError(err)
    });
  }
}

function onClose() {
  setError("");
  handleClose();
}

const handleFacebookLogin = async (response) => {
  await FBLogin(
    {
      AccessToken: response.accessToken,
      RememberMe : false
    }, {
    onSuccess: data => {
      console.log("asdasd" + data.email + " : " + data.username + " : " + data.message + "::" + JSON.stringify(data));

      showSuccessToast(data.message, "top-center");
      setError("");
      handleClose(data.email, data.username);
    },
    onError: e => console.log("errr " + e)
  })
};

const handleFacebookFail = (error) => {
  setError("Login Failed!: " + JSON.stringify(error))
};

const handleGoogleLoginNeedValidation = async (code) => {

  let requestData = {
    Code: code,
    RememberMe : false,
    RedirectUrl: window.location.origin
  };

  await GLogin(requestData, {
    onSuccess: (data) => {
      showSuccessToast(data.message, "top-center");
      setError("");
      handleClose(data.email, data.username);
      console.log(data.email + "::" + data.username);
    },
    onError: (e) => handleGoogleFail(e)
  });

};

const handleGoogleFail = (error) => {
  if (error.error == "access_denied")
  setError("Login Failed!: access denied");
  else 
  setError("Login Failed!: " + JSON.stringify(error))
};

const handleRecaptcha = async (value) => {
  // Handle the reCAPTCHA value

  await ValidateCaptchaApi({ captchaValue: value }, {
    onSuccess: data => {
      setCaptchaResolved(true);
      console.log("reCAPTCHA resolved:");
    },
    onError: e => { setCaptchaResolved(false); console.log("err " + JSON.stringify(e)); }
  })
};

const [open, setOpen] = useState(false);
return (
  <Modal show={show} onHide={onClose} centered className="signup-modal">
    <Modal.Body>
      <button className="btn-close" type="button" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
        <i className="fa fa-times"></i>
      </button>
      <div className="modal-body__banner">
        <img
          width="100"
          height="80"
          src="./images/svg/signup.svg"
          alt="signup"
        />
        <h3>Sign up</h3>
        <p>Join us today!</p>
      </div>
      <form className="modal-body__form" onSubmit={(e) => { e.preventDefault(); onSubmitClick(); }}>
        <div className="inputsContainer">
          <div className="row gx-3">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="group-control">
                  <input
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      placeholder="First Name"
                      onChange={(e) => setName(e.target.value)}
                    />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
              <div className="group-control">
                <input
                  className="form-control"
                  type="text"
                  autoComplete="off"
                  placeholder="Last Name"
                  onChange={(e) => setLast(e.target.value)}
                />
                </div>
              </div>
            </div>
            <div className="row gx-3">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="group-control">
                    <input
                      className="form-control"
                      type="email"
                      autoComplete="off"
                      placeholder="E-mail"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                 <div className="group-control">
                  <input
                      className="form-control"
                      type="password"
                      autoComplete="off"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
              </div>
            </div>
            <div className="row gx-3">
              <div className="col-12 collbx">
                <button
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                Optional Fields
                <i className="cricon"><FontAwesomeIcon icon={faChevronRight} /></i>
              </button>
              </div>
            </div>
            <Collapse in={open}>
              <div>
                <div className="inputsContainer">
                  <div className="row gx-3">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="group-control">
                        <input
                          className="form-control"
                          type="text"
                          autoComplete="off"
                          placeholder="Phone Number"
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row gx-3">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="group-control">
                      <input
                          className="form-control"
                          type="text"
                          autoComplete="off"
                          placeholder="Address"
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="group-control">
                      <input
                            className="form-control"
                            type="text"
                            autoComplete="off"
                            placeholder="City"
                            onChange={(e) => setCity(e.target.value)}
                          />
                      </div>
                    </div>
                  </div>
                  <div className="row gx-3">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="group-control">
                       <input
                            className="form-control"
                            type="text"
                            autoComplete="off"
                            placeholder="State"
                            onChange={(e) => setState(e.target.value)}
                          />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="group-control">
                        <input
                          className="form-control"
                          type="text"
                          autoComplete="off"
                          placeholder="Country"
                          onChange={(e) => setCountry(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
            <div className="captchabox">
              <ReCAPTCHA
              sitekey="6Lej67wZAAAAACKFR3pEzuWazzFZgvi8ZlBigfhz"
              onChange={handleRecaptcha}
              />
            </div>        
          </div>
        <div className="forget">
          <div className="form-check fit">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="registerConfirm"
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="registerConfirm">
              I accept the <a href="/">Terms of Service</a>
            </label>
          </div>
        </div>
        {formattedError && <p className="error-text" style={{ color: 'red', whiteSpace: 'pre-line' }}>{formattedError}</p>}  
        
        <div className="buttonsContainer">
          <button className="btn w-100 primary" type="submit" aria-label="submit">
            Sign up
          </button>
        </div>
        {mode !== "app" && (
          <>
            <div className="sl-title">or sign up with</div>
            <div className="buttonsContainer dflex-row">
              <GoogleOAuthProvider clientId={config.googlePublicClientId}>
                <LoginGoogle onFinish={code => handleGoogleLoginNeedValidation(code)} onError={e => handleGoogleFail(e)} />
              </GoogleOAuthProvider>
              <FacebookLogin
                appId={config.facebookApiKey}
                render={renderProps => (
                  <button onClick={renderProps.onClick} className="btn w-50 facebook" type="button" aria-label="Facebook">
                    <img
                      src="./images/facebook-icon.svg"
                      style={{ marginRight: '3px', width: '21px' }}
                    />
                    Facebook
                  </button>)
                }
                onSuccess={async (response) => { handleFacebookLogin(response); }}
                onFail={(error) => handleFacebookFail(error)}
              />
            </div>
          </>
        )}
        <button
          className="sup-btn"
          type="button"
          data-bs-target="#loginModal"
          data-bs-toggle="modal"
          data-bs-dismiss="modal"
          aria-label="open modal"
          onClick={handleLoginButtonClicked}
            >
            Already have an account? <span>Login</span>
        </button>    
       
         </form>
    </Modal.Body>
  </Modal>
);
}

export default SignUpModal;
