// TermsConditions.js
import React, { useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { CircularProgressbar } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';

import {
  faArrowRight,
  faClockFour,
  faFileVideo,
  faLaptopCode,
  faDownload,
  faPlay,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-tooltip-lite";

function TermsConditions() {
  const headerStyle = {
    position: 'relative',
    borderBottom: '1px solid rgba(255, 255, 255, 0.8)',
  };

  const getCartItems = () => {
    // Your implementation to get cart items
  };
  return (
    <div className="homepage-wrapper">
      <img src="./images/giphy-new.gif" id="background-video"></img>
      <Header style={headerStyle} getCartItems={getCartItems}/>
      <main>
        <div className="top-box"></div>
        <section className="lpath pb-115">
          <div className="container">
              <div className="row">
                <div className="col-12" style={{maxWidth:'1000px',marginLeft:'auto',marginRight:'auto'}}>
                    <h3 className="username" style={{marginBottom:'20px'}}>Terms and Conditions for Skillbakery.com</h3>
                    <p className="username" style={{marginBottom:'15px'}}>Welcome to <a href="skillbakery.com" target="_blank" rel="noreferrer" style={{color:'#fc6f2f'}}>Skillbakery.com</a> (the "Website"). The following terms and conditions (the "Terms") govern your use of the Website and the services provided therein. By accessing or using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Website.</p>
                    <p className="username" style={{fontWeight:'700',fontFamily:'"Nunito Sans", sans-serif',marginBottom:'6px',fontSize:'17px'}}>1. Acceptance of Terms:</p>
                    <p className="username" style={{marginBottom:'15px'}}>By accessing or using the Website, you agree to be bound by these Terms and all applicable laws and regulations governing the Website. If you do not agree with any of these Terms, you are prohibited from using or accessing this site.</p>
                    
                    <p className="username" style={{fontWeight:'700',fontFamily:'"Nunito Sans", sans-serif',marginBottom:'6px',fontSize:'17px'}}>2. Use of the Website:</p>
                    <p className="username" style={{marginBottom:'15px'}}>You agree to use the Website only for lawful purposes and in a manner that does not infringe upon the rights of others or restrict or inhibit their use and enjoyment of the Website. You are solely responsible for your use of the Website and any content you submit, post, or transmit through the Website.</p>

                    <p className="username" style={{fontWeight:'700',fontFamily:'"Nunito Sans", sans-serif',marginBottom:'6px',fontSize:'17px'}}>3. Registration:</p>
                    <p className="username" style={{marginBottom:'15px'}}>In order to access certain features of the Website, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password.</p>

                    <p className="username" style={{fontWeight:'700',fontFamily:'"Nunito Sans", sans-serif',marginBottom:'6px',fontSize:'17px'}}>4. Intellectual Property Rights:</p>
                    <p className="username" style={{marginBottom:'15px'}}>All content on the Website, including but not limited to text, graphics, logos, images, videos, and software, is the property of <a href="skillbakery.com" target="_blank" rel="noreferrer" style={{color:'#fc6f2f'}}>Skillbakery.com</a> or its licensors and is protected by copyright, trademark, and other intellectual property laws. You agree not to reproduce, modify, distribute, or otherwise use any content from the Website without the prior written consent of <a href="skillbakery.com"  rel="noreferrer" style={{color:'#fc6f2f'}}>Skillbakery.com</a>.</p>

                    <p className="username" style={{fontWeight:'700',fontFamily:'"Nunito Sans", sans-serif',marginBottom:'6px',fontSize:'17px'}}>5. User Content:</p>
                    <p className="username" style={{marginBottom:'15px'}}>You may have the opportunity to submit, post, or transmit content to the Website, including but not limited to comments, reviews, and messages (collectively, "User Content"). By submitting User Content, you grant <a href="skillbakery.com" target="_blank" rel="noreferrer" style={{color:'#fc6f2f'}}>Skillbakery.com</a> a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the User Content in connection with the Website and <a href="skillbakery.com" target="_blank" rel="noreferrer" style={{color:'#fc6f2f'}}>Skillbakery.com</a>'s business, including without limitation for promoting and redistributing part or all of the Website.</p>

                    <p className="username" style={{fontWeight:'700',fontFamily:'"Nunito Sans", sans-serif',marginBottom:'6px',fontSize:'17px'}}>6. Third-Party Links:</p>
                    <p className="username" style={{marginBottom:'15px'}}>The Website may contain links to third-party websites or resources. <a href="skillbakery.com" target="_blank" rel="noreferrer" style={{color:'#fc6f2f'}}>Skillbakery.com</a> is not responsible for the content, products, or services offered by third parties, and your use of such third-party websites or resources is at your own risk. <a href="skillbakery.com" target="_blank" rel="noreferrer" style={{color:'#fc6f2f'}}>Skillbakery.com</a> does not endorse or make any representations about third-party websites or resources or their content.</p>

                    <p className="username" style={{fontWeight:'700',fontFamily:'"Nunito Sans", sans-serif',marginBottom:'6px',fontSize:'17px'}}>7. Disclaimer of Warranties:</p>
                    <p className="username" style={{marginBottom:'15px'}}>The Website is provided on an "as is" and "as available" basis, without any warranties of any kind, express or implied. <a href="skillbakery.com" target="_blank" rel="noreferrer" style={{color:'#fc6f2f'}}>Skillbakery.com</a> makes no warranties or representations about the accuracy, completeness, or reliability of any content on the Website or the availability or quality of any products or services offered through the Website.</p>

                    <p className="username" style={{fontWeight:'700',fontFamily:'"Nunito Sans", sans-serif',marginBottom:'6px',fontSize:'17px'}}>8. Limitation of Liability:</p>
                    <p className="username" style={{marginBottom:'15px'}}>In no event shall <a href="skillbakery.com" target="_blank" rel="noreferrer" style={{color:'#fc6f2f'}}>Skillbakery.com</a> be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Website or the content, products, or services offered through the Website, whether based on warranty, contract, tort (including negligence), or any other legal theory, even if <a href="skillbakery.com" target="_blank" rel="noreferrer" style={{color:'#fc6f2f'}}>Skillbakery.com</a> has been advised of the possibility of such damages.</p>


                    <p className="username" style={{fontWeight:'700',fontFamily:'"Nunito Sans", sans-serif',marginBottom:'6px',fontSize:'17px'}}>9. Indemnification:</p>
                    <p className="username" style={{marginBottom:'15px'}}>You agree to indemnify, defend, and hold harmless <a href="skillbakery.com" target="_blank" rel="noreferrer" style={{color:'#fc6f2f'}}>Skillbakery.com</a> and its officers, directors, employees, agents, and affiliates from and against any and all claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with your use of the Website or any violation of these Terms.</p>

                    <p className="username" style={{fontWeight:'700',fontFamily:'"Nunito Sans", sans-serif',marginBottom:'6px',fontSize:'17px'}}>10. Modifications to Terms:</p>
                    <p className="username" style={{marginBottom:'15px'}}><a href="skillbakery.com" target="_blank" rel="noreferrer" style={{color:'#fc6f2f'}}>Skillbakery.com</a> reserves the right to modify or replace these Terms at any time without prior notice. It is your responsibility to review these Terms periodically for changes. Your continued use of the Website following the posting of any changes to these Terms constitutes acceptance of those changes.</p>

                    <p className="username" style={{fontWeight:'700',fontFamily:'"Nunito Sans", sans-serif',marginBottom:'6px',fontSize:'17px'}}>11. Governing Law:</p>
                    <p className="username" style={{marginBottom:'15px'}}>These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.</p>

                    <p className="username" style={{fontWeight:'700',fontFamily:'"Nunito Sans", sans-serif',marginBottom:'6px',fontSize:'17px'}}>12. Contact Us:</p>
                    <p className="username" style={{marginBottom:'15px'}}>If you have any questions about these Terms, please contact us at [<a href="mailto:contact@skillbakery.com" style={{color:'#fc6f2f'}}>contact@skillbakery.com</a>].</p>

                    <p className="username">By using the Website, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use the Website.</p>

                </div>
              </div>
          </div>
        </section>
      </main>
      <Footer/>
    </div>
  );
}

export default TermsConditions;