import { useEffect, useState, useRef  } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BeforeLoginModal from "./BeforeLoginModal";

import {ListOwnedProducts,StripePayment,GetPreSignedUrl} from "../services/api_payments";
import { IsAuthenticated, GetUserInfo, Logout, UpdateUserInfo } from "../services/api_auth";

import {
  faArrowRight,
  faCalendarCheck,
  faClockFour,
  faDownload,
  faFileVideo,
  faLaptopCode,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import StarRatingBasic from "./StarRatingBasic";
import CourseDetailsModal from "./CourseDetailsModal";
import Tooltip from 'react-tooltip-lite';


function FeatureCourseCard(props) {
  const [show, setShow] = useState(false);
  const item = props.data;
  const activeTab = props.data;
  const addToCart = props.addToCart;
  const isUSDCurrency = props.isUSDCurrency;
  const isLoggedIn = props.isLoggedIn;
  const [showBeforeLoginModal, setShowBeforeLoginModal] = useState(false);
  const [products,setProducts] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleBeforeLoginModal = () => {
    setShowBeforeLoginModal(true);
  };
  
  //console.log(isLoggedIn);

  function calculateDiscountPercent(originalCost, discountedCost) {
    if (originalCost <= 0 || discountedCost < 0) {
      throw new Error('Original cost should be greater than 0, and discounted cost should be non-negative.');
    }
  
    const discountAmount = originalCost - discountedCost;
    const discountPercent = (discountAmount / originalCost) * 100;
  
    return discountPercent.toFixed(2);
  }
 
  let discountInrCost = 0;
  let discountedCost = 0; // USD
  if(item?.CoursePrice > 0){
    discountInrCost = 600;
    discountedCost = 8; // USD
   }
  const CourseDiscount = item?.CoursePrice > 0
    ? isUSDCurrency
      ? calculateDiscountPercent(item?.CoursePrice, discountedCost)
      : calculateDiscountPercent(item?.CoursePrice*80, discountInrCost)
    : 0;

    const [isFlipped, setIsFlipped] = useState(false);

    const handleCardFlip = () => {
      setIsFlipped(!isFlipped);
    };
    
  return (
    <>
       

      <div
        className={`col-lg-4 col-md-6 col-12 mix tab-animation ${
          activeTab ? "active" : ""
        }`}
      >
        <div className={`flip-container ${isFlipped ? 'flipped' : ''}`}>
          <div className="flip-card">
            <div className="flip-card-inner">
              {/* Front Side */}
              <div className="flip-card-front">
              <div className="feature">
                <div className="feature__head">
                  <img
                    className="w-100"
                    width="100"
                    height="100"
                    src={item?.CourseImageURL200H}
                    alt="course image"
                  />
                  <span>By: {item?.CoursePublisher}</span>
                </div>
                <div className="feature__body">
                <div className="category-box">
                  <div className="category">{item?.CourseCategory}</div>
                    <div className="rate">
                      {StarRatingBasic(item?.CourseRating)}
                      {/* <span>4.5 (225)</span> */}
                    </div>
                  </div>
                  <div className="feature-price" style={{ display: 'none' }}>
                    <div className="pricebx" >
                      <div className="price" >
                        {!isUSDCurrency ? (
                          <div className="india">
                            <del>{(item?.CoursePrice * 80).toFixed(2)}₹</del>
                          </div>
                        ):(
                          <div className="usa">
                            <del>({item?.CoursePrice}$)</del>
                          </div>
                        )}
                        <span className="d-inline-block  btn green py-1 ml-3 px-2 off-text">
                          {CourseDiscount}% off
                        </span>
                      </div>
                      <div className="price">
                      {!isUSDCurrency ? (
                        <div className="india">
                          {discountInrCost.toFixed(2)}
                          ₹
                        </div>
                      ): (
                        <div className="usa">
                          {(discountedCost).toFixed(2)}
                          $
                        </div> 
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="feature-title">
                    <h3>{item?.CourseTitle}</h3>
                    <p>
                      {item?.CourseDescription.replace(/<(.|\n)*?>/g, "").slice(
                        0,
                        75
                      )}
                      {item?.CourseDescription.length > 75 ? "..." : null}
                    </p>
                  </div>
                  <div className="feature-info">
                    <div className="lessons">
                      <i>
                        <FontAwesomeIcon icon={faFileVideo} />
                      </i>
                      <span>{item?.CourseCurriculum.filter(item => item._class === "lecture").length} Lessons</span>
                    </div>
                    <div className="hours">
                      <i>
                        <FontAwesomeIcon icon={faClockFour} />
                      </i>

                      <span>{Math.round(item?.ContentLengthInMins / 60)} hours</span>
                    </div>
                    <div className="students1" style={{ display: 'none' }}>
                      <i>
                        <FontAwesomeIcon icon={faLaptopCode} />
                      </i>
                      {}
                    </div>
                  </div>
                  <div className="feature-rate">
                    <div className="buttons">
                    <Tooltip content="Download this course" tagName="button" >
                      <button className="buy" aria-label="Download this course" 
                      onClick={() => (isLoggedIn ? addToCart('Download', item, isUSDCurrency ? discountedCost : discountInrCost) : handleBeforeLoginModal())}>
                          <span>{isUSDCurrency ? discountedCost  + '$': discountInrCost + '₹'}</span>
                          <i>
                            <FontAwesomeIcon icon={faDownload} />
                          </i>
                        </button>
                    </Tooltip>
                    <Tooltip content="Rent this course" tagName="button">
                      <button className="buy rent" aria-label="Rent this course" 
                      onClick={() => (isLoggedIn ? addToCart('Rent', item, isUSDCurrency ? (discountedCost-2) : (discountInrCost-100)) : handleBeforeLoginModal())}>
                        <span>{isUSDCurrency ? (discountedCost-2)  + '$': (discountInrCost-100) + '₹'}</span>
                        <img src="./images/rent-icon.svg"/>
                      </button>
                    </Tooltip>
                    <button
                        className="details-btn"
                        type="button"
                        onClick={() => {
                          // Add a delay of 500 milliseconds (you can adjust the duration)
                          setTimeout(() => {
                            handleShow();
                          }, 100);
                          handleCardFlip();
                        }}
                      >
                        <span>Details</span>
                        <i>
                          <FontAwesomeIcon icon={faArrowRight} />
                        </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </div>

              {/* Back Side */}
              <div className="flip-card-back">
              <div className="feature">
                <div className="feature__head">
                  <img
                    className="w-100"
                    width="100"
                    height="100"
                    src={item?.CourseImageURL200H}
                    alt="course image"
                  />
                  <span>By: {item?.CoursePublisher}</span>
                </div>
                <div className="feature__body">
                <div className="category-box">
                  <div className="category">{item?.CourseCategory}</div>
                    <div className="rate">
                      {StarRatingBasic(item?.CourseRating)}
                      {/* <span>4.5 (225)</span> */}
                    </div>
                  </div>
                  <div className="feature-price" style={{ display: 'none'}}>
                    <div className="pricebx">
                      <div className="price" >
                        {!isUSDCurrency ? (
                          <div className="india">
                            <del>{(item?.CoursePrice * 80).toFixed(2)}₹</del>
                          </div>
                        ):(
                          <div className="usa">
                            <del>({item?.CoursePrice}$)</del>
                          </div>
                        )}
                        <span className="d-inline-block  btn green py-1 ml-3 px-2 off-text">
                          {CourseDiscount}% off
                        </span>
                      </div>
                      <div className="price">
                      {!isUSDCurrency ? (
                        <div className="india">
                          {discountInrCost.toFixed(2)}
                          ₹
                        </div>
                      ): (
                        <div className="usa">
                          {(discountedCost).toFixed(2)}
                          $
                        </div> 
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="feature-title">
                    <h3>{item?.CourseTitle}</h3>
                    <p>
                      {item?.CourseDescription.replace(/<(.|\n)*?>/g, "").slice(
                        0,
                        75
                      )}
                      {item?.CourseDescription.length > 75 ? "..." : null}
                    </p>
                  </div>
                  <div className="feature-info">
                    <div className="lessons">
                      <i>
                        <FontAwesomeIcon icon={faFileVideo} />
                      </i>
                      <span>{item?.CourseCurriculum.filter(item => item._class === "lecture").length} Lessons</span>
                    </div>
                    <div className="hours">
                      <i>
                        <FontAwesomeIcon icon={faClockFour} />
                      </i>

                      <span>{Math.round(item?.ContentLengthInMins / 60)} hours</span>
                    </div>
                    <div className="students1" style={{ display: 'none'}}>
                      <i>
                        <FontAwesomeIcon icon={faLaptopCode} />
                      </i>
                      <span>15 Students</span>
                    </div>
                  </div>
                  <div className="feature-rate">
                    <div className="buttons">
                    <Tooltip content="Download this course" tagName="button" >
                      <button className="buy" aria-label="Download this course" 
                      onClick={() => (isLoggedIn ? addToCart('Download', item, isUSDCurrency ? discountedCost : discountInrCost) : handleBeforeLoginModal())}>
                          <span>{isUSDCurrency ?  '$'+discountedCost  : discountInrCost + '₹'}</span>
                          <i>
                            <FontAwesomeIcon icon={faDownload} />
                          </i>
                        </button>
                    </Tooltip>
                    <Tooltip content="Rent this course" tagName="button">
                      <button className="buy rent" aria-label="Rent this course" 
                      onClick={() => (isLoggedIn ? addToCart('Rent', item, isUSDCurrency ? (discountedCost-2) : (discountInrCost-100)) : handleBeforeLoginModal())}>
                        <span>{isUSDCurrency ? '$'+(discountedCost-2) : (discountInrCost-100) + '₹'}</span>
                        <img src="./images/rent-icon.svg"/>
                      </button>
                    </Tooltip>
                    <button
                        className="details-btn"
                        type="button"
                        onClick={() => {
                          // Add a delay of 500 milliseconds (you can adjust the duration)
                          setTimeout(() => {
                            handleShow();
                          }, 100);
                          handleCardFlip();
                        }}
                      >
                        <span>Details</span>
                        <i>
                          <FontAwesomeIcon icon={faArrowRight} />
                        </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>

        
        
      </div>
      {show ? (
        <CourseDetailsModal
          courseData={item}
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
          isUSDCurrency={isUSDCurrency}
          addToCart={addToCart}
          isLoggedIn={isLoggedIn}
        />
      ) : null}
	{showBeforeLoginModal && (
    <BeforeLoginModal handleClose={() => setShowBeforeLoginModal(false)} />
  )}
    </>
  );
}

export default FeatureCourseCard;