// config.js
const config = {
    AWSCloudfrontURL: 'https://dht5iq4hmh972.cloudfront.net',
    
    baseUrl: "https://courseapi.skillbakery.com",
    googlePublicClientId:"324317094583-4tjm11ogifbla9icnreour8uipaasvjf.apps.googleusercontent.com",
    facebookApiKey:"3049592635159788"
    

    /*
    facebookApiKey:"1072856270513455",
    googlePublicClientId:"718567103018-ua8j4o9d112rp25ftpvb1gg69efsn6av.apps.googleusercontent.com", //moje
    baseUrl: "https://localhost:4433",
    */
    
};

export default config;