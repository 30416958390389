import { useEffect, useState, useRef  } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faQuoteLeft,
  faTimes,
  faDownload,
  faList,
  faExternalLink,
  faIndent,
  faCode,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, Tabs, Tab, Accordion, Button } from "react-bootstrap";
import StarRatingBasic from "./StarRatingBasic";
import {ListOwnedProducts,StripePayment,GetPreSignedUrl} from "../services/api_payments";
import { IsAuthenticated, GetUserInfo, Logout, UpdateUserInfo } from "../services/api_auth";
import config from "../_config/config";
import Tooltip from 'react-tooltip-lite';
import BeforeLoginModal from "./BeforeLoginModal";


function CourseDetailsModal({ isLoggedIn,courseData, handleClose, show,isUSDCurrency,addToCart }) {
  const [curriculum, setCurriculum] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [enableDownload, setEnableDownload] = useState(false);
  const videoRef = useRef(null);
  const [products,setProducts] = useState(null);
  const [isLeftVisible, setIsLeftVisible] = useState(true);
  const [isRightButtonVisible, setIsRightButtonVisible] = useState(false);
  const [showBeforeLoginModal, setShowBeforeLoginModal] = useState(false);
  const [activeLectureId, setActiveLectureId] = useState(null);

  const toggleSections = () => {
    setIsLeftVisible((prev) => !prev);
    setIsRightButtonVisible((prev) => !prev);
  };

  function calculateDiscountPercent(originalCost, discountedCost) {
    if (originalCost <= 0 || discountedCost < 0) {
      throw new Error('Original cost should be greater than 0, and discounted cost should be non-negative.');
    }
  
    const discountAmount = originalCost - discountedCost;
    const discountPercent = (discountAmount / originalCost) * 100;
  
    return discountPercent.toFixed(2);
  }
 
  let discountInrCost = 0;
  let discountedCost = 0; // USD
  if(courseData?.CoursePrice > 0) {
   discountInrCost = 600;
   discountedCost = 8; // USD
  }
  const CourseDiscount = courseData?.CoursePrice > 0
    ? isUSDCurrency
      ? calculateDiscountPercent(courseData?.CoursePrice, discountedCost)
      : calculateDiscountPercent(courseData?.CoursePrice*80, discountInrCost)
    : 0;

  const handleBeforeLoginModal = () => {
    setShowBeforeLoginModal(true);
  };

  async function AcquireOwnedProducts() {
    await ListOwnedProducts({
      onSuccess: async (data) => {
        // Sort the paymentData array based on paymentDate in descending order
        const sortedPaymentData = data.slice().sort((a, b) => b.paymentDate - a.paymentDate);
        setProducts(sortedPaymentData);
        console.log("Products " + JSON.stringify(data));
      },
      onError: e => console.log("AcquireOwnedProducts err: " + e)
    });
  }

  useEffect(() => {
    fixData();
    setVideoUrl(courseData && courseData?.CourseIntroVideoURL);
    
      const GetUserInfoIfAuthenticated = async () => {
        await GetUserInfo({
          onSuccess: (data) => {
            AcquireOwnedProducts();
          },
          onError: e => console.log("GetUserInfo err: " + e)
        });
      }
    GetUserInfoIfAuthenticated();
  }, [courseData]);

  const fixData = () => {
    const courseCurriculum = [];
    let currentChapter = null;
    courseData &&
      courseData.CourseCurriculum.forEach((item) => {
        if (item._class === "chapter") {
          currentChapter = {
            title: item.title,
            lectures: [],
          };
          courseCurriculum.push(currentChapter);
        } else if (currentChapter) {
          currentChapter.lectures.push(item);
        }
      });
    setCurriculum(courseCurriculum);
  };

  async function AwsUrl(filename) {
    try {
      filename="courselist/"+courseData.CourseId+"/"+filename;
      console.log("filename is "+filename);
      let response;//"courselist/Lecture2Section1.mp4"
       await GetPreSignedUrl(filename, "", {
        onSuccess: s => response=s,
        onError: e => {
          console.log("AwsUrl err: " + JSON.stringify(e));
          throw e; // Rethrow the error to be caught by the calling code
        }
      });

      if (response && response.PreSignedUrl) {
        return response.PreSignedUrl; // Return the PreSignedUrl
      } else {
        console.log("Invalid response format from GetPreSignedUrl.");
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error in AwsUrl: ", error);
      throw error; // Rethrow the error to be caught by the calling code
    }
  }
  const handleLectureClick = (lecture,courseData) => {
    if (lecture?.asset?.asset_type === "Video") {
      fetchDataAndSetVideoUrl(lecture?.asset?.title, courseData);
      setActiveLectureId(lecture.id);
    }
  };
  const fetchDataAndSetVideoUrl = async (filename,course) => {
    try {
      
        // Check if there are products and any non-expired subscription
        if (products && Array.isArray(products) && products.length > 0) {
          const nonExpiredSubscription = products.find(product =>
              (
                  (product.type === "Subscription" || product.type === "Download" || product.type === "Rent") &&
                  !isSubscriptionExpired(product.expirationDate) &&
                  (product.productId === "SUBYearly" || product.productId === "SUBMonthly" || String(product.productId) === String(course.CourseId))
              )
          );
    
          if (nonExpiredSubscription) {
            // Non-expired subscription found, fetch the URL
            const url = await AwsUrl(filename);
            // Determine enableDownload value based on product type
            const enableDownload = nonExpiredSubscription.type === "Download";
            videoUrlHandler(url, enableDownload);
          } else {
            console.log("No non-expired subscriptions found.");
            // Handle the case where there are products but none of them are non-expired subscriptions
          }
        } else {
          console.log("No products or empty product array.");
          // Handle the case where there are no products or the product array is empty
        }
      } catch (error) {
        console.error("Error fetching AWS URL: ", error);
        // Handle the error as needed
      }
  };

  const isSubscriptionExpired = (expirationDate) => {
    const currentDate = new Date();
    const expirationDateObj = new Date(expirationDate * 1000); // Convert from seconds to milliseconds
  
    return expirationDateObj < currentDate;
  };
  const videoUrlHandler = (url,enableDownload ) => {
    setVideoUrl(url);
    setEnableDownload(enableDownload);
  };

  const videoSectionRef = useRef(null);

  useEffect(() => {
    // Remove the existing video element
    // console.log("Yes I am being called");
    if (videoRef.current) {
      videoRef.current.parentNode.removeChild(videoRef.current);
    }

    // Create a new video element
    const newVideo = document.createElement("video");
    newVideo.width = 640;
    newVideo.height = 360;
    newVideo.controls = true;
    newVideo.autoplay = true;
    newVideo.poster="./images/videobanner.png"
    // Set controls attribute based on enableDownload
    newVideo.controls = true;
    if (!enableDownload) {
      newVideo.controlsList = "nodownload";
    }
    // Create a source element
    const source = document.createElement("source");
    source.src = videoUrl;
    source.type = "video/mp4";

    // Append the source to the video
    newVideo.appendChild(source);

    // Append the video to the container
    document.querySelector(".cousreDetails__video").appendChild(newVideo);

    // Update the videoRef to the new video element
    videoRef.current = newVideo;

    const updateSubInfoHeight = () => {
      const videoSection = videoSectionRef.current;
      const subInfoSection = document.querySelector(".cousreDetails__subInfo");
    
      if (videoSection && subInfoSection) {
        // Use requestAnimationFrame to ensure the DOM has updated
        requestAnimationFrame(() => {
          const videoHeight = videoSection.getBoundingClientRect().height;
          const staticHeight = 62; // Subtract 62px
    
          // Calculate dynamic height based on video height and subtract static height
          const dynamicHeight = videoHeight - staticHeight;
    
          // Apply min-height and max-height to subInfoSection
          subInfoSection.style.minHeight = `${dynamicHeight}px`;
          subInfoSection.style.maxHeight = `${dynamicHeight}px`;
        });
      }
    };
    
    // Initial update
    updateSubInfoHeight();
    
    // Update the subInfo height whenever the window is resized
    window.addEventListener("resize", updateSubInfoHeight);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSubInfoHeight);
    };

  }, [videoUrl,enableDownload]);

// Get the course title and convert it to lowercase
const courseTitle = courseData?.CourseTitle.toLowerCase();
// Replace spaces with dashes
const formattedTitle = courseTitle.replace(/ /g, "-").replace("#","sharp");
// Generate the link with the prefix and formatted title
const link = `#course-details/${formattedTitle}`;

var GithubURL = courseData?.GithubURL;
// Now you can use this link variable wherever you need it

  return (
    <Modal show={show} onHide={handleClose} className="coursePopup right" centered fade fullscreen>
      <Modal.Body>
        <button className="btn-close" type="button" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} />
          <i className="fa fa-times"></i>
        </button>
        <div className="cousreDetails">
          <div className="row gx-0">
            <div className={`left-section ${isLeftVisible ? 'col-12 col-lg-4' : 'd-none'}`}>
            <button onClick={toggleSections} className="cur-btn">
              <div>
                <i>
                <FontAwesomeIcon icon={faList} /> &nbsp;&nbsp;
                </i>
                Curriculum</div>
              <div className="clbtn">
                <i>
                <FontAwesomeIcon icon={faTimes} />
                </i>
              </div>
            </button>
              {/* curriculum */}
              <div className="cousreDetails__subInfo mt-0">
                  <div>
                    <Accordion
                      defaultActiveKey="0"
                      style={{ width: "99%" }}
                      className="curriculum-accordion"
                    >
                      {curriculum &&
                        curriculum.map((item, index) => (
                          <Accordion.Item eventKey={index} key={index}>
                            <Accordion.Header>{item.title}</Accordion.Header>
                            <Accordion.Body>
                              {item.lectures.map((lecture) => (
                                <div
                                  key={lecture?.id}
                                  className={`curriculum-box d-flex mt-2 rounded border shadow-sm p-2 ${activeLectureId === lecture.id ? 'active' : ''}`}
                                  onClick={() =>
                                    lecture?.asset?.asset_type === "Video"
                                      ? handleLectureClick(lecture,courseData)
                                      : () => {
                                          return;
                                        }
                                  }
                                >
                                  <div className="icon me-3">
                                    <img
                                      //src={"./images/svg/logo.svg"}
                                      src={courseData?.CourseImageURL200H}
                                      alt="thumbnail"
                                      style={{ maxWidth: "100px" }}
                                      className="mr-3"
                                    />
                                  </div>
                                  <div className="content flex-grow-1">
                                    <div className="d-flex mb-2">
                                      <span className="btn secondary  py-0 px-3 h-auto font-small">
                                        {lecture?.asset?.asset_type}
                                      </span>
                                      {/* {lecture?.is_free ? (
                                        <span className="btn green py-0 font-small px-2 ms-auto">
                                          Free
                                        </span>
                                      ) : (
                                        <span className="btn primary h-auto py-0 px-3 font-small ms-auto">
                                          Paid
                                        </span>
                                      )} */}
                                    </div>
                                    <p className="font-weight-bold">
                                      {lecture?.title}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                    </Accordion>
                  </div>
                </div>
            </div>
            <div className={`right-section ${isRightButtonVisible ? 'col-lg-12' : 'col-12 col-lg-8 cd-order1'}`} style={{position:'relative'}}>
                
            {!isLeftVisible && (
                <button onClick={toggleSections} className="cur-btn2">
                <div>
                <i>
                <FontAwesomeIcon icon={faIndent} /> &nbsp;&nbsp;
                </i></div>
                </button>
              )}

                <div className="cousreDetails__video object-fit-fill" ref={videoSectionRef}>
                    {/* <video width="640" height="360" controls autoPlay>
                      <source src={videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video> */}
                </div>
                <div className="cousreDetails__mainInfo mt-4">
                <div className="title" style={{flexDirection:'column'}}>
                <div className="lang" style={{marginLeft:'auto', marginBottom:'20px'}}>
                    <button className="btn outline" aria-label="language">
                      <i className="fa fa-earth"></i>
                      <span>English</span>
                    </button>
                    <button className="btn primary" aria-label="category">
                      {courseData?.CourseCategory}
                    </button>
                  </div>
                  <div className="info">
                    <h3>{courseData?.CourseTitle}</h3>
                    <div className="rate">
                      <div className="rate-stars">
                        {StarRatingBasic(courseData?.CourseRating)}
                      </div>
                      {/* <span>{courseData?.CourseRating} (225)</span> */}
                    </div>
                  </div>
                </div>
                <div className="price align-items-center" style={{display:'none'}}>
                {!isUSDCurrency ? (
                    <div className="india">
                      {" "}
                      <del>{(courseData?.CoursePrice * 80).toFixed(2)}₹ </del>
                    </div>
                  ) :  (
                    <div className="usa">
                      <del>({courseData?.CoursePrice}$)</del>
                    </div>
                  )}
                  {!isUSDCurrency ? (
                    <div className="india">
                      {(
                        (courseData?.CoursePrice -
                          (CourseDiscount / 100) * courseData?.CoursePrice) *
                        80
                      ).toFixed(2)}
                      ₹
                    </div>
                  ):(
                    <div className="usa">
                      {(
                        courseData?.CoursePrice -
                        (CourseDiscount / 100) * courseData?.CoursePrice
                      ).toFixed(2)}
                      $
                    </div>
                   )}
                  <span className="d-inline-block  btn green py-1 ml-3">
                    {CourseDiscount}% off
                  </span>
                </div>
                <div className="buyContainer">
                  <div className="buy">
                    <Tooltip content="Download this course" tagName="button">
                      <button className="buy" aria-label="Download this course" 
                      onClick={() => (isLoggedIn ? addToCart('Download', courseData, isUSDCurrency ? discountedCost : discountInrCost) : handleBeforeLoginModal())}>
                          <span>{isUSDCurrency ? '$'+discountedCost : discountInrCost + '₹'}</span>
                          <i>
                            <FontAwesomeIcon icon={faDownload} />
                          </i>
                        </button>
                    </Tooltip>
                    <Tooltip content="Rent this course" tagName="button">
                      <button className="buy rtc" aria-label="Rent this course" 
                      onClick={() => (isLoggedIn ? addToCart('Rent', courseData, isUSDCurrency ? (discountedCost-2) : (discountInrCost-100)) : handleBeforeLoginModal())}>
						          <span>{isUSDCurrency ? '$'+(discountedCost-2) : (discountInrCost-100) + '₹'}</span>
                        <img src="./images/rent-icon.svg"/>
                      </button>
                    </Tooltip>
                    {/* <button className="btn green" aria-label="buy" onClick={()=>StripePayment(
                    [
                      {
                        Id: courseData.CourseId,
                        CourseTitle:courseData.CourseTitle, 
                        CoursePrice:courseData.CoursePrice,
                        Currency:"usd",
                        Quantity:1,
                      }
                    ]
                    )}>
                      Buy now for ${courseData?.CoursePrice}
                    </button>
                    <button className="btn mint" aria-label="buy" onClick={()=>StripePayment(
                    [
                      {
                        Id: courseData.CourseId,
                        CourseTitle:courseData.CourseTitle, 
                        CoursePrice:(courseData.CoursePrice* 83).toFixed(2),
                        Currency:"inr",
                        Quantity:1,
                      }
                    ]
                    )}>
                      Buy now for ₹{(courseData?.CoursePrice * 83).toFixed(2)}
                    </button> */}
                  </div>
                  {/* <button className="cart btn outline" aria-label="cart">
                    <i>
                      <FontAwesomeIcon icon={faCartShopping} />
                    </i>
                  </button> */}
                  <Tooltip content="Open in new tab" tagName="button">
                    <a className="oint-link" target="_blank" href={link} style={{marginLeft:'15px'}}>
                      <i className="far fa-external-link">
                        <FontAwesomeIcon icon={faExternalLink} />
                      </i>
                    </a>
                  </Tooltip>
                  {GithubURL !== '#' && (
                    <Tooltip content="Access Code" tagName="button">
                        <a className="oint-link getcode" target="_blank" href={GithubURL}>
                          <i className="far fa-external-link">
                            <FontAwesomeIcon icon={faCode} />
                          </i>
                        </a>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div style={{marginTop:'20px'}}
                dangerouslySetInnerHTML={{
                  __html: courseData?.CourseDescription,
                }}
                >
              </div>
            </div>
          </div>
        </div>
        {showBeforeLoginModal && (
          <BeforeLoginModal handleClose={() => setShowBeforeLoginModal(false)} />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default CourseDetailsModal;
