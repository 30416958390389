import { useEffect, useState, useRef  } from "react";
import { useParams } from "react-router-dom";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Contact from "../Contact";
import BeforeLoginModal from "../../components/BeforeLoginModal";
import CourseDetailsModal from "../../components/CourseDetailsModal";
import coursesData from "../../_library/courses.json"; // Import your courses data
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faClosedCaptioning,
  faStar,
  faPlay
} from "@fortawesome/free-solid-svg-icons";


const CourseDetails = ({isLoggedIn,setIsLoggedIn,addToCart,removeFromCart,getCartItems,cartItemCount,isUSDCurrency,toggleCurrency,emptyCart}) => {
  const { courseName } = useParams();
  const [show, setShow] = useState(false);
  const [course, setCourse] = useState(null);
  const [showBeforeLoginModal, setShowBeforeLoginModal] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const headerStyle = {
    position: 'relative',
    borderBottom: '1px solid rgba(255, 255, 255, 0.8)',
  };

  useEffect(() => {
    // Find the course with the given course name
    const formattedCourseName = courseName.replace("sharp", "#");
    const foundCourse = coursesData.CoursesCollection.find(
      (course) =>
        course.CourseTitle.replace(/ /g, "-").toLowerCase() ===
        formattedCourseName
    );
    setCourse(foundCourse);
  }, [courseName]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  if (!course) {
    return <div>Course not found!</div>;
  }

  const handleCardFlip = () => {
    setIsFlipped(!isFlipped);
  };

  //let formattedCourseName = courseName.replace("sharp","#");
  // Find the course with the given course name
  //const course = coursesData.CoursesCollection.find((course) => course.CourseTitle.replace(/ /g, "-").toLocaleLowerCase() === formattedCourseName);
  // Calculate total duration and lecture count
   let totalDuration = 0;
   let lectureCount = 0;
 
   if (course) {
     course.CourseCurriculum.forEach(lecture => {
       // Check if the lecture belongs to _class: lecture
       if (lecture._class === 'lecture') {
         totalDuration += lecture.asset.time_estimation;
         lectureCount++;
       }
     });
   }
 
   // Format duration to HH:mm format
   const formattedDuration = new Date(totalDuration * 1000)
     .toISOString()
     .substr(11, 8);

   // Extract the CourseCategory
   const courseCategory = course.CourseCategory;

   // Filter all courses based on the CourseCategory
   const matchingCourses = coursesData.CoursesCollection.filter(course => course.CourseCategory === courseCategory);
   
 
  return (
    <div className="homepage-wrapper">
      <img src="./images/giphy-new.gif" id="background-video"></img>
      <Header setIsLoggedIn={setIsLoggedIn} removeFromCart={removeFromCart} getCartItems={getCartItems} cartItemCount={cartItemCount} isUSDCurrency={isUSDCurrency} toggleCurrency={toggleCurrency} isLoggedIn={isLoggedIn}/>
      <main>
      <div class="top-box"></div>
        <section className="pb-115">
            <div className="container">
                <div className="row g-0">
                    <div className="col-lg-7 col-12 order-bottom">
                      <div className="d-block d-sm-none">
                        <figure className="spcd-img">
                        <img src={course.CourseImageURL200H} alt={course.CourseTitle} style={{width:'100%'}} />
                        </figure>
                      </div>
                      <div className="spcd-box">
                        <h2 className="course-til">{course.CourseTitle}</h2>
                        <div className="mb-3">
                          <div className="d-flex flex-shrink-0 align-items-center">
                          <img src="./images/icon.webp" className="instr-pic"/>
                          <div class="ms-2 flex flex-col justify-center">
                            <span class="text-gray-700">Instructor</span>
                            <a class="flex hover:underline flex-shrink-0" href="#"><h2 class="font-semibold text-base">SkillBakery Studio</h2></a></div>
                          </div>
                        </div>
                        <div className="mb-5">
                          <div className="d-flex flex-shrink-0 align-items-center">
                            <div>
                              <i className="far fa-clock">
                                <FontAwesomeIcon icon={faClock} />
                              </i>
                              <span> {formattedDuration}</span>
                            </div>
                            <div className="ms-2  ms-3">
                              <i className="far fa-closed-captioning">
                                <FontAwesomeIcon icon={faClosedCaptioning} />
                              </i>
                              <span> {lectureCount} Lessons</span>
                            </div>
                            <div className="d-flex flex-shrink-0 align-items-center ms-3">
                              <div className="spcd-rating">
                                <i className="far fa-star">
                                  <FontAwesomeIcon icon={faStar} />
                                  <FontAwesomeIcon icon={faStar} />
                                  <FontAwesomeIcon icon={faStar} />
                                  <FontAwesomeIcon icon={faStar} />
                                  <FontAwesomeIcon icon={faStar} />
                                </i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-block d-sm-none">
                          <button
                            className="spcd-swbtn"
                            type="button"
                            onClick={() => {
                              // Add a delay of 500 milliseconds (you can adjust the duration)
                              setTimeout(() => {
                                handleShow();
                              }, 100);
                              handleCardFlip();
                            }}
                            >
                            <i>
                              <FontAwesomeIcon icon={faPlay} />
                            </i>
                            <span>Start Watching</span>
                          </button>
                        </div>
                        <div>
                          <p className="course-des" dangerouslySetInnerHTML={{ __html: course.CourseDescription }}></p>
                        </div>
                        {/* <div>
                          <div className="wywl-box">
                            <h5>What you'll learn:</h5>
                            <ul>
                              <li>what problems React can solve</li>
                              <li>how React solves those problems under the hood</li>
                              <li>what JSX is and how it translates to regular JavaScript function calls and objects</li>
                              <li>manage state with hooks</li>
                              <li>build forms</li>
                            </ul>
                          </div>
                        </div> */}
                        <div>
                          <div className="mt-5">
                            <h5>You might also like these resources:</h5>
                            <ul className="mt-4 spcdc-list">

                            {matchingCourses.map(course => {
                               // Calculate total course duration
                              let totalSeconds = 0;
                              course.CourseCurriculum.forEach(item => {
                                if (item._class === 'lecture' && item.asset) {
                                  totalSeconds += item.asset.time_estimation;
                                }
                              });

                              // Convert totalSeconds to HH:mm format
                              const hours = Math.floor(totalSeconds / 3600);
                              const minutes = Math.floor((totalSeconds % 3600) / 60);
                              const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
                              // Get the course title and convert it to lowercase
                              const courseTitle = course?.CourseTitle.toLowerCase();
                              // Replace spaces with dashes
                              const formattedTitle = courseTitle.replace(/ /g, "-").replace("#","sharp");
                              // Generate the link with the prefix and formatted title
                              const link = `#course-details/${formattedTitle}`;
                              return (
                                <li key={course.CourseId}>
                                  <a href={link} className="course-card">
                                    <div className="row">
                                      <div className="col-lg-4">
                                        <figure className="mb-0">
                                          <img src={course.CourseImageURL100x100} style={{ width: '100%' }} alt={course.CourseTitle} />
                                        </figure>
                                      </div>
                                      <div className="col-lg-8">
                                        <h5 className="course-card-title">{course.CourseTitle}</h5>
                                        <p className="course-card-summary">{course.CourseAuthor} • {formattedDuration} • {course.CourseCurriculum.filter(item => item._class === 'lecture').length} Lectures</p>
                                        {/* <p className="course-card-para" dangerouslySetInnerHTML={{ __html: course.CourseDescription }}></p> */}
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              );
                            })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-12 order-top">
                      <div className="d-none d-sm-block">
                        <figure className="spcd-img">
                         <img src={course.CourseImageURL200H} alt={course.CourseTitle} style={{width:'100%'}} />
                        </figure>
                        <button
                          className="spcd-swbtn"
                          type="button"
                          onClick={() => {
                            // Add a delay of 500 milliseconds (you can adjust the duration)
                            setTimeout(() => {
                              handleShow();
                            }, 100);
                            handleCardFlip();
                          }}
                          >
                          <i>
                            <FontAwesomeIcon icon={faPlay} />
                          </i>
                          <span>Start Watching</span>
                        </button>
                      </div>
                      <div className="spcdc-box">
                        <h3 className="curr-til">Course Curriculum</h3>
                        <ol>
                          {course.CourseCurriculum.map((item) => (
                          <li key={item.id}>{item.title}</li>
                        ))}
                        </ol>
                      </div>
                    </div>
                </div>
            </div>
            {show ? (
              <CourseDetailsModal
                courseData={course}
                show={show}
                handleClose={handleClose}
                handleShow={handleShow}
                isUSDCurrency={isUSDCurrency}
                addToCart={addToCart}
                isLoggedIn={isLoggedIn}
              />
            ) : null}
          {showBeforeLoginModal && (
            <BeforeLoginModal handleClose={() => setShowBeforeLoginModal(false)} />
          )}
        </section>
      </main>
      <Contact />
      <Footer/>
    </div>
  );
}

export default CourseDetails;