import { Link, useParams, useNavigate } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          <div className="footer__logo">
            <a className="item-link" href="/">
              <img
                width="170"
                height="100"
                src="./images/logo.svg"
                alt="logo"
              />
            </a>
          </div>
          <ul className="footer__links">
            {/* <li>
              <a href="/">About</a>
            </li> */}
            {/* <li>
              <a href="#ourcourse">Our Courses</a>
            </li>
            <li>
              <a href="https://blog.skillbakery.com/">Blog</a>
            </li> */}
            <li>
              <a href="#contact">Contact</a>
            </li>
            <li>
              <Link className="item-link" to="/Faq">
                FAQ
              </Link>
            </li>
            {/* <li>
              <a href="/">Help & Support</a>
            </li> */}
            <li>
              <a href="https://skillbakery.com/tos.html">Terms of Service</a>
            </li>
            <li>
              <a href="https://skillbakery.com/return.html" class="item-link">Refund Policy</a>
            </li>
            <li>
              <a href="https://skillbakery.com/privacy.html" class="item-link">Privacy Policy</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="copyright__content">
            <div className="social_media">
              <h3>FOLLOW US</h3>
              <ul>
                <li>
                  <a href="/" target="_blank" rel="noreferrer">
                    <img
                      width="100"
                      height="100"
                      src="./images/svg/gmail.svg"
                      alt="gmail"
                    />
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank" rel="noreferrer">
                    <img
                      width="100"
                      height="100"
                      src="./images/svg/linkedin.svg"
                      alt="linkedin"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/skillbakery"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      width="100"
                      height="100"
                      src="./images/svg/facebook.svg"
                      alt="facebook"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/skillbakery"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      width="100"
                      height="100"
                      src="./images/svg/twitter.svg"
                      alt="twitter"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/c/Skillbakery"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      width="100"
                      height="100"
                      src="./images/svg/youtube.svg"
                      alt="youtube"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <p>
              &copy; 2023-2024 SkillBakery Studio | All Rights Reserved
              <a href="https://idevtek.com"> IDevTek</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
