import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const PaymentsConfirmation = () => {
  const {status} =useParams();
  const navigate = useNavigate();

  useEffect(()=>
  {
    setTimeout(()=>navigate("/"),3000);
  },[]);

  const renderSections = () => {
    return (
      <div className="container">
        <h2 className="section-head">Payment Confirming:</h2>
        {status == 0 && <h2 className="text-center" style={{color:"red"}}>Payment Canceled</h2>}
        {status == 1 && <h2 className="text-center" style={{color:"Chartreuse"}}>Payment Succesfull</h2>}
        
      </div>
    );
};

  return (
    <div className="homepage-wrapper">
       {/* <video id="background-video" autoPlay loop muted>
        <source src="./images/giphy-new.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <img src="./images/giphy-new.gif" id="background-video"></img>
        <div className="top-box"></div>
        <section className="lpath pb-115">
         {renderSections()}
        </section>
    </div>
  );
};

export default PaymentsConfirmation;

