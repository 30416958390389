import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../assets/styles/main.css';

export const showSuccessToast = (message, position="top-right") => {
  toast.success(
    message, 
    {
    position: position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    className: 'custom-toast-container-class'
    , 
    //style: { whiteSpace: 'nowrap', color:"red" }

  });
};

export const showFailedToast = (message,position="top-right") => {
  toast.error(message, {
    position: position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

