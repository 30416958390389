import React, { useState,useEffect, useRef } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Container, Row, Col, Nav, Tab, Tabs, Button, input, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IsAuthenticated, GetUserInfo, Logout, UpdateUserInfo } from "../../services/api_auth";
import { showSuccessToast } from "../../components/ToastNotifications";
import Alert from "../../components/Alert";
import { ListOwnedProducts,StripeRefreshSubscriptionsList} from "../../services/api_payments"
const MyProfile = ({ addToCart,removeFromCart,cartItemCount,isUSDCurrency,toggleCurrency,getCartItems,emptyCart,isLoggedIn,setIsLoggedIn}) => {
    const headerStyle = {
        position: 'relative',
        borderBottom: '1px solid rgba(255, 255, 255, 0.8)',
    };
    const [activeTab, setActiveTab] = useState('profile');
    const [userProfile, setUserProfile] = useState(null);
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [products,setProducts] = useState(null);
    const [fcname,setFcname] = useState('');
    const [fclname,setFclname] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleClick = () => {
            UpdUserInfo(); // Call the async function when the button is clicked
    };
    const convertTimestampToLocalTime = (timestamp) => {
        const date = new Date(timestamp * 1000); // Multiply by 1000 to convert to milliseconds
        return date.toLocaleString();
    };
    const getCurrencySymbol = (currency) => {
        return currency === 'USD' ? '$' : '₹'; // Assuming '₹' for INR, modify as needed
    };
    async function UpdUserInfo() { 
        let updateInfo =
        {
          Name: userProfile.name,
          LastName: userProfile.lastName,
          UserName: userProfile.userName,
          Address: address,
          PhoneNumber: phoneNumber,
          City: city,
          State: state,
          Country: country,
        };
        await UpdateUserInfo(updateInfo, {
          onSuccess: (data) => {
            console.log("User Data Updated");
            showSuccessToast("Data Saved Successfully","top-center");
          },
          onError: (e) => console.log(e)
        });
         // Set the updatedUserProfile to the state
         setUserProfile(userProfile);
    }
    async function AcquireOwnedProducts() {
        await StripeRefreshSubscriptionsList(
              {
                onSuccess:(s)=>{},
                onError:(e)=>{}
              }
            );
        await ListOwnedProducts({
          onSuccess: async (data) => {
            // Sort the paymentData array based on paymentDate in descending order
            const sortedPaymentData = data.slice().sort((a, b) => b.paymentDate - a.paymentDate);
            setProducts(sortedPaymentData);
            setLoading(false);  // Stop loading once data is loaded
            console.log("Products " + JSON.stringify(data));
          },
          onError: e => console.log("AcquireOwnedProducts err: " + e)
        });
      }
    useEffect(() => {
        const GetUserInfoIfAuthenticated = async () => {
            setLoading(true);  // Start loading
            setError(null);  // Reset errors
                await GetUserInfo({
                  onSuccess: (data) => {
                    setUserProfile((prevUserProfile) => {
                        // Only update if the data is different from the previous state
                        return JSON.stringify(prevUserProfile) !== JSON.stringify(data) ? data : prevUserProfile;
                      });
                    AcquireOwnedProducts();
                    console.log("AAA" + JSON.stringify(data));
                  },
                  onError: e => {
                    console.log("GetUserInfo err: " + e);
                    setError(e);  // Set error state
                    setLoading(false);  // Stop loading on error
                }
                });
            }
        GetUserInfoIfAuthenticated();
      },[]);
      
     useEffect(() => {
        // Update input field values when userProfile changes
        if (userProfile) {
          setName(userProfile.name || '');
          setLastName(userProfile.lastName || '');
          setPhoneNumber(userProfile.phoneNumber || '');
          setAddress(userProfile.address || '');
          setCity(userProfile.city || '');
          setState(userProfile.state || '');
          setCountry(userProfile.country || '');
          setFcname(userProfile?.name ? userProfile.name.charAt(0) : '');
          setFclname(userProfile?.lastName ? userProfile.lastName.charAt(0) : '');
        }
      }, [userProfile]);
    
    return (
        <div className="homepage-wrapper">
            {/* <video id="background-video" autoPlay loop muted>
                <source src="./images/giphy-new.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video> */}
            <img src="./images/giphy-new.gif" id="background-video"></img>
            <Header style={headerStyle} removeFromCart={removeFromCart} getCartItems={getCartItems} cartItemCount={cartItemCount} isUSDCurrency={isUSDCurrency} toggleCurrency={toggleCurrency} emptyCart={emptyCart} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>
            <main>
                <div className="top-box"></div>
                <section className="lpath pb-115">
                    <Container fluid className="profile-sec text-white container">
                        <Row>
                            {/* Left Section - Tabs */}
                            <Col md={3} className="border-right px-0">
                                <div className="pimg-sec">
                                    <div className="form-group mt-3">
                                       <p className="pc-img">{fcname}{fclname}</p>
                                    </div>
                                    <div className="form-group mb-5">
                                        <h5>{userProfile?.name} {userProfile?.lastName}</h5>
                                    </div>
                                </div>
                                <Nav className="flex-column profile-nav mb-5">
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="profile"
                                            active={activeTab === 'profile'}
                                            onClick={() => setActiveTab('profile')}
                                            className="py-3"
                                        >
                                            My Profile
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="transactions"
                                            active={activeTab === 'transactions'}
                                            onClick={() => setActiveTab('transactions')}
                                            className="py-3"
                                        >
                                            My Transactions
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>

                            {/* Right Section - Tab Content */}
                            <Col md={9} className="px-0">
                                {activeTab === 'profile' && (
                                    <>
                                        <div className="form-group p-3 rhs-bx bottom-border">
                                            <h4 className="mb-2">Profile Bio</h4>
                                            <h6 className="mb-2">Customize your profile by adding details.</h6>
                                        </div> 
                                        <div className="form-group p-3 pt-5 pb-5">
                                           <div style={{maxWidth:'600px', marginLeft:'auto', marginRight:'auto'}}>
                                                <div className="form-group mb-4">
                                                    <input
                                                        className="form-control dark"
                                                        type="email"
                                                        autoComplete="off"
                                                        placeholder="E-mail"
                                                        readOnly
                                                        value={userProfile?.email}
                                                    />
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input
                                                        className="form-control dark"
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="Phone Number"
                                                        maxLength={18}
                                                        value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input
                                                        className="form-control dark"
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="Address"
                                                        maxLength={200}
                                                        value={address} onChange={(e) => setAddress(e.target.value)} 
                                                    />
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input
                                                        className="form-control dark"
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="City "
                                                        maxLength={30}
                                                        value={city} onChange={(e) => setCity(e.target.value)} 
                                                    />
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input
                                                        className="form-control dark"
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="State "
                                                        maxLength={20}
                                                        value={state} onChange={(e) => setState(e.target.value)} 
                                                    />
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input
                                                        className="form-control dark"
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="Country"
                                                        maxLength={20}
                                                        value={country} onChange={(e) => setCountry(e.target.value)} 
                                                    />
                                                </div>
                                                <div className="form-group mb-4">
                                                    <button className="btn px-5 primary" type="button" aria-label="submit" onClick={handleClick}>
                                                        Save
                                                    </button>
                                                </div>
                                           </div>
                                        </div>
                                    </>
                                )}
                                {activeTab === 'transactions' && (
                                    <>
                                        <div className="form-group p-3 rhs-bx bottom-border">
                                            <h4 className="mb-2">Transaction Overview</h4>
                                            <h6 className="mb-2">Efficiently monitor transactions with our accessible tools for ultimate control.</h6>
                                            <Alert/>
                                        </div> 
                                        <div className="form-group p-3 pt-5 pb-5">
                                           <div style={{maxWidth:'870px', marginLeft:'auto', marginRight:'auto'}}>
                                            {loading && <div>Loading transactions...</div>}
                                            {error && <div>Error loading transactions: {error.message}</div>}
                                            {!loading && !error && (
                                            <Table striped bordered responsive variant="dark" id="paymentTable">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Description</th>
                                                        <th>Amount</th>
                                                        <th>Expiry</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {   products.map((payment, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{convertTimestampToLocalTime(payment.paymentDate)}</td>
                                                        <td>{payment.title}</td>
                                                        <td>{getCurrencySymbol(payment.currency)}{payment.amountPaid}/{payment.type}</td> {/* Assuming $ for USD, modify as needed */}
                                                        <td>{convertTimestampToLocalTime(payment.expirationDate)}</td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            )}
                                           </div>
                                        </div>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer/>
        </div>
    );
}
  
  export default MyProfile;