import SubscriptionCard from "../../components/SubscriptionCard";
import SubscriptionDownloadRentCard from "../../components/SubscriptionDownloadRentCard";
export default function Subscription({ isLoggedIn,removeFromCart,cartItemCount,isUSDCurrency,toggleCurrency,getCartItems,addToCart  }) {
  const data = [
    {
      maintitle:"Monthly",
      title:   (
        <>
          Unlock <strong>unlimited access</strong> to <strong>all courses</strong> for 30 days with seamless online viewing.
        </>
      ), listcontent: (
        <>
          <ul className="sclist">
            <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
            <span>30-day unlimited access.</span>
            </li>
            <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
            <span>Seamless online viewing.</span>
            </li>
            <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
            <span>Dive into limitless learning.</span>
            </li>
          </ul>
        </>
      ),
      inrPrice: 3000,
      usdPrice: 40,
      discount: 0,
      Tenure:"Monthly",
      buttonLabel:"Get Started"
    },
    {
      maintitle:"Yearly",
      title:   (
        <>
          <strong>Gain a full year</strong> of access to all courses and <strong>enjoy</strong> significant <strong>savings</strong> with this exclusive offer.
        </>
      ),
      listcontent: (
        <>
          <ul className="sclist">
            <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
            <span>1-year access, big savings!</span>
            </li>
            <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
            <span>Exclusive offer.</span>
            </li>
            <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
            <span>Unlock a year of learning.</span>
            </li>
          </ul>
        </>
      ),
      inrPrice: 36000,
      usdPrice: 500,
      discount: 98.6,
      Tenure:"Yearly",
      buttonLabel:"Get Started"
    }
  ];
  const dataDownloadRent = [
    {
      maintitle:"Download",
      title: (
        <>
          Access courses at a specified price and enjoy <strong>offline viewing</strong> by <strong>downloading</strong> them for later use.
        </>
      ),
      listcontent: (
        <>
          <ul className="sclist">
            <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
            <span>Explore courses at a set price.</span>
            </li>
            <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
            <span>Download for offline viewing.</span>
            </li> 
            <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
            <span>1 Year (365 days) course subscription</span>
            </li>
            <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
            <span>Seamless online viewing.</span>
            </li>
          </ul>
        </>
      ),
      inrPrice: 600,
      usdPrice: 8,
      discount: 0,
      buttonLabel:   (
        <>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download" class="svg-inline--fa fa-download " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"></path></svg>
        </>
      ),
    },
    {
      maintitle:"Rent",
      title:  (
        <>
          Subscribe to a specific course for a <strong>1 Year (365 days) period</strong> and enjoy seamless <strong>online</strong> viewing.
        </>
      ),
      listcontent: (
        <>
          <ul className="sclist">
            <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
            <span>1 Year (365 days) course subscription.</span>
            </li>
            <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
            <span>Seamless online viewing.</span>
            </li>
            <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
            <span>Learn on your schedule.</span>
            </li>
          </ul>
        </>
      ),
      inrPrice: 500,
      usdPrice: 6,
      discount: 0,
      buttonLabel:   (
        <>
        <img src="./images/rent-icon2.svg" style={{width:'27px'}}/>
        </>
      ),
    }
  ];
  return (
    <section className="subscription pb-115">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
          <div className="logo-slider">
              <div className="slide-track">
                <div className="slide">
                  <img src="./images/logo/angular-icon1.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/azure-icon.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/backbone-icon.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/blazor-icon.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/cordova.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/css-icon.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/ember-icon.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/flutter-logo.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/jquery-1.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/kotlin.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/laravel-icon.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/logo-javascript.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/meteor-icon.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/mysql-logo.svg" height="70"  alt="" />
                </div>

                <div class="slide">
                  <img src="./images/logo/nodejs.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/php-logo.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/python-icon.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/reactjs.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/azure-icon.svg" height="70"  alt="" />
                </div>
                <div class="slide">
                  <img src="./images/logo/typescript-2.svg" height="70"  alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h2 className="section-head">Offers</h2>
        <p className="text-center">
          Provides access to all the courses on our platform
        </p>
        <div className="row g-4">
          {/* <!-- Start Card--> */}
          {dataDownloadRent &&
            dataDownloadRent?.map((item, index) => (
              <div className="col-lg-3 col-md-6 col-12" key={index}>
                <SubscriptionDownloadRentCard
                  maintitle={item?.maintitle}
                  title={item?.title}
                  inrPrice={item?.inrPrice}
                  usdPrice={item?.usdPrice}
                  discount={item?.discount}
                  tenure={item?.Tenure}
                  isUSDCurrency={isUSDCurrency}
                  listcontent={item.listcontent}
                  addToCart={addToCart} 
                  buttonLabel={item.buttonLabel}
                />
              </div>
            ))}
          {data &&
            data?.map((item, index) => (
              <div className="col-lg-3 col-md-6 col-12" key={index}>
                <SubscriptionCard
                  maintitle={item?.maintitle}
                  title={item?.title}
                  inrPrice={item?.inrPrice}
                  usdPrice={item?.usdPrice}
                  discount={item?.discount}
                  tenure={item?.Tenure}
                  isUSDCurrency={isUSDCurrency}
                  listcontent={item.listcontent}
                  addToCart={addToCart} 
                  buttonLabel={item.buttonLabel}
                  isLoggedIn={isLoggedIn} 
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}
