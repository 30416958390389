// DynamicWidthDiv.js
import React, { useState, useEffect, useRef } from 'react';

function DynamicWidthDiv({ onWidthChange }) {
  const [isLessThan1917, setIsLessThan1917] = useState(true);
  const divRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      const divWidth = divRef.current.offsetWidth;
      setIsLessThan1917(divWidth < 1917);
      onWidthChange(divWidth);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
   }, [divRef, onWidthChange]);

  return (
    <div
      ref={divRef}
      className={`dynamic-width-div ${isLessThan1917 ? 'less-than-1917' : ''}`} >
    </div>
  );
}

export default DynamicWidthDiv;
