import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClockFour,
  faFileVideo,
  faLaptopCode,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

export default function CartItem(props) {
  const removeFromCart  = props.removeFromCart;
  return (
    <div className="course">
      <div className="course__image">
        <img
          className="w-100"
          width="100"
          height="50"
          src={props.cartItem.Image}
          alt={props.cartItem.Title}
        />
      </div>
      <div className="course__info">
        <div className="base-info" style={{gap:'0px'}}>
          <h3>{props.cartItem.Title}</h3>
          <div className="sub-info">
            {/* <div className="lessons">
              <i>
                <FontAwesomeIcon icon={faFileVideo} />
              </i>
              <span>10 Lessons</span>
            </div>
            <div className="hours">
              <i>
                <FontAwesomeIcon icon={faClockFour} />
              </i>

              <span>10 hours</span>
            </div>
            <div className="students1">
              <i>
                <FontAwesomeIcon icon={faLaptopCode} />
              </i>
              <span>15 Students</span>
            </div> */}
          </div>
        </div>
        <div className="tagbx"><span className={`cdim-text tagbx ${props.cartItem.Type}`}>{props.cartItem.Type}</span></div>
        <div className="control">
          <div className="price">
            <div className="india currency-hide">{props.cartItem.Amount}</div>
            <span></span>
            <div className="usa">{props.cartItem.Amount}</div>
          </div>
          <button aria-label="remove" data-id={props.cartItem.ID}  onClick={() => removeFromCart(props.cartItem.ID)}>
            <i className="fa fa-trash">
              <FontAwesomeIcon icon={faTrash} />
            </i>
          </button>
        </div>
      </div>
    </div>
  );
}
