import React, { useState,useRef } from "react";
import Header from "../../layouts/Header";
import { Modal, Tabs, Tab, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router";
import { ResetPassword } from "../../services/api_auth";


function ResetPasswordSuccessPage() {
  const [resetInitiated, setResetInitiated] = useState(false);
  const { token,email } = useParams();
  const [errorText, setErrorText] = useState("");
  const formattedError = errorText.replace(/<br>/g, "\n");
  const newPassword = useRef(null);
  const confirmPassword = useRef(null);
  const navigate = useNavigate();


  const handleResetInitiate = async () => {
    setErrorText("");

    if (!newPassword.current || newPassword.current.value.trim() === '') {
      setErrorText("Password cannot be empty");
    }
    else if (newPassword.current.value !== confirmPassword.current.value) {
      setErrorText("Passwords do not match");
    }
    else 
    {
      await ResetPassword(
        {
          email:email, 
          token:token,
          newPassword:newPassword.current.value, 
          confirmPassword:confirmPassword.current.value
        },
        {
          onSuccess: (response)=>{
            setResetInitiated(true);
            setTimeout(()=>navigate("/"),3000);
          },
          onError: (e)=>setErrorText(e),
       }
        );
    }
  };

  return (
    <div className="homepage-wrapper">
       {/* <video id="background-video" autoPlay loop muted>
        <source src="./images/giphy-new.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <img src="./images/giphy-new.gif" id="background-video"></img>
      <main>
        <section className="conatiner d-flex h-100 justify-content-center align-items-center">
          {!resetInitiated ? (
            <div className="rsp-box">
              <div className="modal-body__banner">
                <img
                  width="100"
                  height="100"
                  src="./images/svg/password_icon.svg"
                  alt="signin"
                />
                <h3>Set new password</h3>
                <p>Must be at least 8 characters.</p>
              </div>
              <form>
                <div className="inputsContainer">
                  <div className="group-control mt-4 mb-2">
                    <input
                      className="form-control"
                      type="password"
                      autoComplete="off"
                      placeholder="Password"
                      ref ={newPassword}
                    />
                  </div>
                  <div className="group-control mt-3 mb-4">
                    <input
                      className="form-control"
                      type="password"
                      autoComplete="off"
                      placeholder="Confirm Password"
                      ref ={confirmPassword}
                    />
                  {formattedError && <p className="error-text" style={{ color: 'red', whiteSpace: 'pre-line' }}>{formattedError}</p>}
                  </div>
                </div>
                <Button
                  className="btn w-100 primary"
                  variant="primary"
                  onClick={handleResetInitiate}
                >
                  Reset Password
                </Button>
              </form>
            </div>
          ) : (
            <div className="rsp-box">
              <div className="modal-body__banner">
                <img
                  className="mt-3"
                  width="100"
                  height="100"
                  src="./images/svg/success_icon.svg"
                  alt="signin"
                />
                <h3 className="mt-3">All done!</h3>
                <p className="mt-2">Your password has been successfully reset.</p>
              </div>
            </div>
          )}
        </section>
      </main>
    </div>
  );
}

export default ResetPasswordSuccessPage;
