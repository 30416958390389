import React,{ useState,useEffect } from "react";
import Header from "../../layouts/Header";
import Subscription from "../Subscription";
import OwnPace from "../OwnPace";
import Contact from "../Contact";
import Footer from "../../layouts/Footer";
import Banner from "../Banner";
import OurCourses from "../OurCourses";
import LazyLoadComponent from '../../components/LazyLoadComponent';
import MyCourses from "../MyCourses";

export default function Homepage({ isLoggedIn,setIsLoggedIn,addToCart,removeFromCart,cartItemCount,isUSDCurrency,toggleCurrency,getCartItems,emptyCart }) {
  //const {} = useHomepage();
  useEffect(() => {

  }, [isLoggedIn]);

  return (
  	<div className="container-fluid" style={{paddingLeft:'0px', paddingRight:'0px'}}>
    <div className="homepage-wrapper">
      {/* <video id="background-video" autoPlay loop muted>
        <source src="./images/giphy-new.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <img src="./images/giphy-new.gif" id="background-video"></img>

      {/* header  starts*/}
      <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} removeFromCart={removeFromCart} getCartItems={getCartItems} cartItemCount={cartItemCount} isUSDCurrency={isUSDCurrency} toggleCurrency={toggleCurrency} emptyCart={emptyCart}/>
      {/* header  ends*/}

      <main>
        <Banner addToCart={addToCart} isUSDCurrency={isUSDCurrency}/>
        <LazyLoadComponent>
        {/* Subscription starts */}
          <Subscription isLoggedIn={isLoggedIn} addToCart={addToCart} removeFromCart={removeFromCart} getCartItems={getCartItems} cartItemCount={cartItemCount} isUSDCurrency={isUSDCurrency} toggleCurrency={toggleCurrency}/>
        {/* Subscription ends */}
        </LazyLoadComponent>
        <LazyLoadComponent>
        {/* Own Peace starts */}
        <OwnPace />
        {/* Own Peace ends */}
        </LazyLoadComponent>
        {isLoggedIn && (
            <LazyLoadComponent>
              <MyCourses isLoggedIn={isLoggedIn}  addToCart={addToCart} isUSDCurrency={isUSDCurrency} />
            </LazyLoadComponent>
        )}
        <LazyLoadComponent>
        <OurCourses isLoggedIn={isLoggedIn} addToCart={addToCart} isUSDCurrency={isUSDCurrency}/>
        </LazyLoadComponent>

        {/* <LazyLoadComponent> */}
        {/* testimonials starts */}
        {/* <Testimonials /> */}
        {/* testimonials ends */}
        {/* </LazyLoadComponent> */}

        {/* we are starts */}
        {/* <WeAre /> */}
        {/* we are ends */}
        
        {/* <LazyLoadComponent>
        <YoutubeContent />
        </LazyLoadComponent> */}

        <LazyLoadComponent>
        <Contact />
        </LazyLoadComponent>
        <LazyLoadComponent>
        <Footer />
        </LazyLoadComponent>
      </main>
		</div>
    </div>
  );
}
