// Class10OnwardsPage.js
import React, { useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowRight,
  faClockFour,
  faFileVideo,
  faLaptopCode,
  faPlay,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";

const Class10Onwards = (toggleCurrency) => {
    const headerStyle = {
        position: 'relative',
        borderBottom: '1px solid rgba(255, 255, 255, 0.8)',
      };
    
      const getCartItems = () => {
        // Your implementation to get cart items
      };
    
  return (
    <div className="homepage-wrapper">
       {/* <video id="background-video" autoPlay loop muted>
        <source src="./images/giphy-new.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <img src="./images/giphy-new.gif" id="background-video"></img>
      <Header getCartItems={getCartItems}/>
      <main>
        <div className="top-box"></div>
        <section className="pb-115">
            <div className="container">
                <div className="row">
                    <div className="col">
                     <figure className="cfb-box">
                       <img src="./images/class10th-banner.svg"/>
                     </figure>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center section-top-head">
                      <h2 className="section-head">Features</h2>
                      <p className="text-center">Unlock your child's potential with our interactive programming courses</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-12 f-cards">
                      <div>
                        <figure className="mb-0">
                          <img src="./images/fc-1.svg"/>
                        </figure>
                      </div>
                      <div>
                        <h4 className="section-head">Interactive Coding</h4>
                        <p className="mb-0">Make coding fun and engaging exercise for a joyful learning experience.</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 f-cards">
                    <div>
                        <figure className="mb-0">
                          <img src="./images/fc-2.svg"/>
                        </figure>
                      </div>
                      <div>
                        <h4 className="section-head">Step-by-Step Guidance</h4>
                        <p className="mb-0">Clear instructions to help students understand programming concepts.</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 f-cards">
                    <div>
                        <figure className="mb-0">
                          <img src="./images/fc-3.svg"/>
                        </figure>
                      </div>
                      <div>
                        <h4 className="section-head">Real-World Examples</h4>
                        <p className="mb-0">Apply concepts through practical coding examples for effective learning.</p>
                      </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col text-center section-top-head">
                      <h2 className="section-head">Programming Milestones</h2>
                      <p>Start your coding journey today</p>
                    </div>
                </div>
                <div className="row">
                  <div className="col px-0">
                  <div class="timeline1">
                        <div class="outer">
                          <div class="card2">
                            <div class="info">
                              <h3 class="title">HTML</h3>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                            </div>
                          </div>
                          <div class="card2">
                            <div class="info">
                              <h3 class="title">CSS</h3>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                            </div>
                          </div>
                          <div class="card2">
                            <div class="info">
                              <h3 class="title">C#</h3>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                            </div>
                          </div>
                          <div class="card2">
                            <div class="info">
                              <h3 class="title">Python</h3>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                            </div>
                          </div>
                          <div class="card2">
                            <div class="info">
                              <h3 class="title">Scratch</h3>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
            <div className="container mt-5">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                      <div className="col section-top-head">
                        <h2 className="section-head">Common Questions</h2>
                        <p className="text-center">Here are the some of the most common questions that we get</p>
                      </div>
                  </div>
                </div>
                <div className="col-12" style={{marginTop:'35px'}}>
                  <div className="faq-box">
                    <h4 className="cf-q">What programming languages will be taught in SkillBakery Studio?</h4>
                    <p className="cf-a">We will be teaching computer programming using languages such as Python, Scratch, and Java.</p>

                    <h4 className="cf-q">What is the age range for the class 10th students?</h4>
                    <p className="cf-a">The classes are designed for students in class 10th, typically aged between 15-18 years.</p>
                  
                    <h4 className="cf-q">Are the classes suitable for both CBSE and ICSE board students?</h4>
                    <p className="cf-a">Yes, our classes cater to students from both CBSE and ICSE boards.</p>

                    <h4 className="cf-q">What is the teaching approach at SkillBakery Studio?</h4>
                    <p className="cf-a">We believe in making learning fun and engaging. Our classes incorporate interactive activities, games, and projects to make programming enjoyable for students.</p>

                    <h4 className="cf-q">Are there any prerequisites for joining the classes?</h4>
                    <p className="cf-a mb-0">No prior programming experience is required. Our classes are designed to introduce students to programming from scratch.</p>
                  </div>
                </div>
              </div>
            </div>
        </section>
      </main>
      <Footer/>
    </div>
  );
};

export default Class10Onwards;