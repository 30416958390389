import React, { useState } from "react";
import learningPathData from '../../_library/learningpath.json';
import Header from "../../layouts/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BeforeLoginModal from "../../components/BeforeLoginModal";
import CourseDetailsModal from "../../components/CourseDetailsModal";
// import { CircularProgressbar } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';
import {
  faArrowRight,
  faClockFour,
  faFileVideo,
  faLaptopCode,
  faDownload,
  faPlay,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-tooltip-lite";
import jsonData from "../../_library/courses.json";

const developers = learningPathData.roles;

const LearningPath = ({ isLoggedIn,addToCart,removeFromCart,cartItemCount,isUSDCurrency,toggleCurrency,getCartItems }) => {
  const [selectedSection, setSelectedSection] = useState(null);
  const [showBeforeLoginModal, setShowBeforeLoginModal] = useState(false);
  const [show, setShow] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [item,setItem] = useState(null);

  const headerStyle = {
    position: 'relative',
    borderBottom: '1px solid rgba(255, 255, 255, 0.8)',
  };

  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  const handleBackClick = () => {
    setSelectedSection(null);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleCardFlip = () => {
    setIsFlipped(!isFlipped);
  };
  
  const handleBeforeLoginModal = () => {
    setShowBeforeLoginModal(true);
  };

  const fetchCourseById = async (courseId) => {
    try {
      const courses = (await jsonData) && jsonData.CoursesCollection;
      const course = courses.find(course => course.CourseId === courseId);
      return course;
    } catch (error) {
      console.error('Error fetching course:', error);
      return null;
    }
  };
  let discountInrCost = 0;
  let discountedCost = 0; // USD
 
  discountInrCost = 600;
  discountedCost = 8; // USD


  const renderSections = () => {
    if (selectedSection) {
      const developerContent = developers[selectedSection];

      return (
        <div className="container fwc">
          <div className="row">
            <div className="col-1">
              <button className="lpbb mt-3" onClick={handleBackClick}>
                <img className="wi40" src="./images/back-icon.svg" alt="Back to All" />
              </button>
            </div>
            <div className="col-10">
              <h2 className="section-head">{developerContent.label} Roadmap</h2>
             
            </div>
            <div className="col-1"></div>
          </div>
          <div className="row">
            <div className="col">
            <div className="roadmap-main">
            <div className="row roadmap-box">
            {developerContent.details.roadmapContent.courses.map((course, index) => (
              <div className="col-lg-4 col-md-6 col-12" key={index}>
              <div className="containerr">
                <div className="card">
                  <div className="face face1">
                    <div className="content">
                      <img src={course.CourseImageURL100x100} alt={course.CourseTitle}/>
                      <p className="course-title">{course.CourseTitle}</p>
                    </div>
                  </div>
                  <div className="face face2">
                    <div className="content">
                      <p>{course.courseDescription}</p>
                      <div className="feature-info rodmp">
                        <div className="lessons">
                          <FontAwesomeIcon icon={faFileVideo} /> <span>{course.lessons} Lessons</span>
                        </div>
                        <div className="hours">
                          <FontAwesomeIcon icon={faClockFour} /> <span>{course.hours}</span>
                        </div>
                      </div>
                      <div className="rbbox">
                      <div className="buttons">
                        <Tooltip content="Download this course" tagName="button">
                          <button className="buy lp" aria-label="Download this course" onClick={() => (isLoggedIn ? addToCart('Download', course, isUSDCurrency ? discountedCost : discountInrCost) : handleBeforeLoginModal())}>
                          <span>{isUSDCurrency ? discountedCost  + '$': discountInrCost + '₹'}</span>
                            <FontAwesomeIcon icon={faDownload} />
                          </button>
                        </Tooltip>
                        <Tooltip content="Rent this course" tagName="button">
                          <button className="buy lp rent" aria-label="Rent this course" onClick={() => (isLoggedIn ? addToCart('Rent', course, isUSDCurrency ? (discountedCost-2) : (discountInrCost-100)) : handleBeforeLoginModal())}>
                        <span>{isUSDCurrency ? (discountedCost-2)  + '$': (discountInrCost-100) + '₹'}</span>
                            <img src="./images/rent-icon.svg" alt="Rent"/>
                          </button>
                        </Tooltip>
                        <button className="details-btn" type="button" onClick={() => {
                          // Add a delay of 500 milliseconds (you can adjust the duration)
                          setTimeout(async () => {
                            const courseData = await fetchCourseById(course.CourseId);
                            setItem(courseData);
                            handleShow();
                          }, 100);
                          handleCardFlip();}}>
                          <span>Details</span>
                          <i>
                            <FontAwesomeIcon icon={faArrowRight} />
                          </i>
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            ))}
            </div>
            </div>
            </div>
          </div>
        </div>

      );
    } else {
      // Render all sections
      return (
        <div className="container">
          <h2 className="section-head">Your Comprehensive <br></br> Learning Path to Tech Excellence</h2>
          <p className="text-center">Explore, Learn, and Excel in the World of Technology with our Specialized Learning Tracks</p>
          <div className="row g-4 pb-4 custom-row">
            {Object.keys(developers).map((developer, index) => (
              <div className="col-lg-4 col-md-6 col-12" key={index}>
                <div className={`lpath__card box${index + 1}`} onClick={() => handleSectionClick(developer)}>
                  <div className="icr-box">
                    <img src={developers[index].details.cardImage} alt={`${developers[index].label} Image`} />
                  </div>
                  <div className="ccbx">
                    <h4 className="mb-3 mt-4">{developers[index].label}</h4>
                    <h6 className="mb-2 bold">{developers[index].details.subtitle}</h6>
                    {developers[index].details.learningPath.learningPathContent}
                    <button className="btn primary mw200">Start</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
            
        </div>
      );
    }
  };

  return (
    <div className="homepage-wrapper">
      {/* <video id="background-video" autoPlay loop muted>
        <source src="./images/giphy-new.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <img src="./images/giphy-new.gif" id="background-video"></img>
      <Header style={headerStyle} removeFromCart={removeFromCart} getCartItems={getCartItems} cartItemCount={cartItemCount} isUSDCurrency={isUSDCurrency} toggleCurrency={toggleCurrency}/>
      <main>
        <div className="top-box"></div>
        <section className="lpath pb-115">
          {renderSections()}
        </section>
      </main>
      {show && item ? (
        <CourseDetailsModal
          courseData={item}
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
          isUSDCurrency={isUSDCurrency}
          addToCart={addToCart}
          isLoggedIn={isLoggedIn}
        />
      ) : null}
      {showBeforeLoginModal && (
          <BeforeLoginModal handleClose={() => setShowBeforeLoginModal(false)} />
      )}
    </div>
  );
};

export default LearningPath;