import React, { useEffect, useState, useRef } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faEnvelope,
  faMagnifyingGlass,
  faPhone,
  faPhoneAlt,
  faShoppingCart,
  faTimes,
  faUser,
  faHome,
  faAppleAlt
} from "@fortawesome/free-solid-svg-icons";
import SignInModal from "../components/SignInModal";
import SignUpModal from "../components/SignUpModal";
import CartDetailsModal from "../components/CartDetailsModal";
import dataJson from "../_library/courses.json";
import CourseDetailsModal from "../components/CourseDetailsModal";

import { IsAuthenticated, GetUserInfo, Logout, UpdateUserInfo } from "../services/api_auth";
import {
  StripeCreateCustomer,
  StripeSubscriptionPayment,
  StripeCancelSubscription,
  PhonePeSubscriptionPayment,
  StripeRefreshSubscriptionsList,
  GetPreSignedUrl
} from "../services/api_payments"

import { Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import config from '../_config/config'
import cc from "classcat"
const ToggleButton = ({ isOn, toggle }) => (
  <div className="sbtn" onClick={() => toggle(!isOn)}>
    <div
      className={cc({
        circlee: true,
        off: !isOn,
        on: isOn,
      })}
    />
    <span className={cc({ textOff: !isOn })}>{isOn ? '$' : '₹'}</span>
  </div>
)

//function Header({ removeFromCart, cartItemCount, isUSDCurrency, toggleCurrency, getCartItems }) {

function Header({ isLoggedIn, setIsLoggedIn, removeFromCart, cartItemCount, isUSDCurrency, toggleCurrency, getCartItems,emptyCart }) {

  const [showSignIn, setShowSignIn] = useState(false);
  const [showCourseInfoModal, setShowCourseInfoModal] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [courseData, setCourseData] = useState(null);
  const [Username, setUsername] = useState(null);
  const [isOn, toggle] = React.useState({ isOn: true })

  const [isScrolled, setIsScrolled] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const navigate = useNavigate();

  const [showNavList, setShowNavList] = useState(true); 
  const searchInputRef = useRef(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  // Event handler for hiding nav list when search input is clicked
  const handleSearchInputClick = () => {
    setShowNavList(false);
    setIsInputFocused(true);
  };

  // Event handler for showing nav list when focus moves away from search input
  const handleSearchInputBlur = () => {
    setShowNavList(true);
    setIsInputFocused(false);
  };

  useEffect(() => {
    function handleScroll() {
      const currentScrollY = window.scrollY;
      const scrollThreshold = 100; // You can adjust this threshold as needed
      const scrolledUp = currentScrollY < prevScrollY;

      setIsScrolled(scrolledUp);
      setPrevScrollY(currentScrollY);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY]);

  const targetRef = useRef(null); 

  // handle sign in modal
  const handleSignInClose = (...params) => {
    if (params.length !== 0) {
      
      setAuthState(true,params[1]);
      //AcquireOwnedProducts();
      CreateStripeCustomer();
    }
    setShowSignIn(false);

  };

  const handleSignInCreateButton = () => {
    setShowSignIn(false);
    setShowSignUp(true);
  }
  const handleSignInShow = () => setShowSignIn(true);
  const handleSignUpShow = () => setShowSignUp(true);
  // handle sign up modal
  const handleSignUpLoginButton = () => {
    setShowSignIn(true);
    setShowSignUp(false);
  }
  const handleSignUpClose = (...params) => {
    if (params.length !== 0) {
      
      setAuthState(true,params[1])
      //AcquireOwnedProducts();
      CreateStripeCustomer();
    }
    setShowSignUp(false);
  }

   // Update authentication state in local storage with a 2-hour expiry
   const setAuthState = (loggedIn,username) => {
    const expiry = new Date();
    expiry.setTime(expiry.getTime() + 2 * 60 * 60 * 1000); // 2 hours expiry
    const token = sessionStorage.getItem("session_token");
    const refreshtoken = sessionStorage.getItem("session_refresh_token");
    if(token && refreshtoken){
      localStorage.setItem("authState", JSON.stringify({ loggedIn, refreshtoken,expiry,token })); //
    }
    setUsername(username);
    setIsLoggedIn(loggedIn);
  };

  // handle search info modal
  const handleCartClose = () => setShowCart(false);

  const handleCartShow = () => {
    // Fetch or update the cart items as needed
    console.log("I am called again");
    const updatedCartItems = getCartItems();
    setCartItems(updatedCartItems);
    setShowCart(true);
  };

  // Use useEffect to watch for changes in cartItems and update the state accordingly
  useEffect(() => {
    const updatedCartItems = getCartItems();
    setCartItems(updatedCartItems);
  }, [getCartItems]);

  // course information modal
  const handleCourseInfoModalClose = () => {
    setShowCourseInfoModal(false);
    setCourseData(null);
    searchInputRef.current.value = "";
  };
  const handleCourseInfoModalShow = () => setShowCourseInfoModal(true);

  const searchHandler = async (e) => {
    const value = e.target.value;
    if (value.length > 3) {
      setIsSearch(true);
      const res =
        dataJson &&
        dataJson?.CoursesCollection &&
        dataJson?.CoursesCollection.filter(
          (item) =>
            item?.CourseTitle.toLowerCase()?.includes(value) ||
            item?.CourseTitle.toUpperCase()?.includes(value)
        );

      setSearchData(res);
    }
  };
 
  
  async function CancelStripeSubscription(subscriptionId) {
    await StripeCancelSubscription(subscriptionId,
      {
        onSuccess: async (url) => {
          console.log("CancelStripeSubscription " + JSON.stringify(url));
          window.location = url;
        },
        onError: e => console.log("CancelStripeSubscription err: " + e)
      });
  }

  const handleSearchClick = (data) => {
    if (data) {
      handleCourseInfoModalShow();
      setCourseData(data);
    }
  };

  async function DoLogout() {
    Logout({
      onSuccess: () => { setUsername(null); setAuthState(false); localStorage.removeItem("authState"); },
      onError: (e) => console.log(e)
    });
  }


  async function CreateStripeCustomer() {
    await StripeCreateCustomer({
      onSuccess: async (data) => {
        console.log("CreateCustomer " + JSON.stringify(data));
      },
      onError: e => console.log("CreateCustomer err: " + e)
    });

  }

  useEffect(() => {
    //setTimeout(()=>{CreatePhonePeSubscription("price_1OUnKNSCbgFHXELDZ6zIVCFz","title",230,"currency",12)},  3000);
    //setTimeout(()=>{CreateStripeSubscription("price_1OUnKNSCbgFHXELDZ6zIVCFz","title",23,"currency",12)},  3000);
    console.log("Course Details Page...");
    const GetUserInfoIfAuthenticated = async () => {
      await IsAuthenticated({
        onSuccess: async (isAuthenticated) => {
          if (isAuthenticated) {
            await GetUserInfo({
              onSuccess: (data) => {
                console.log("AAA" + JSON.stringify(data));
                
                setAuthState(true,data.userName);
                //AcquireOwnedProducts();
                CreateStripeCustomer();
                console.log("Header Login " + isLoggedIn);
              },
              onError: e => console.log("GetUserInfo err: " + e)
            });
          }
        },
        onError: e => console.log("IsAuthenticated err: " + e)
      });
    }

    GetUserInfoIfAuthenticated();
   
    function handleClickOutside(event) {
      if (targetRef.current && !targetRef.current.contains(event.target)) {
        setIsSearch(false);
        searchInputRef.current.value = "";
      }
    }
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    
  }, [setIsLoggedIn, setUsername]);

  useEffect(() => {
    console.log("Header Login " + isLoggedIn);
  }, [isLoggedIn]);

  useEffect(() => {
    // Check if 'linkTo' exists in localStorage and its value is 'ourcourse'
    console.log("check for ourcourse");
  
    // Delay the execution by 5 seconds
    const delay = 2000; // 5 seconds in milliseconds
    const timeoutId = setTimeout(() => {
      const linkTo = localStorage.getItem('linkTo');
      if (linkTo) {
        const targetElement = document.getElementById(linkTo);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
        // Clear the 'linkTo' value after scrolling
        localStorage.removeItem('linkTo');
      }
    }, delay);
  
    // Clean up the timeout
    return () => clearTimeout(timeoutId);
  }, [])

  const handleAnchorClick = (event, targetId) => {
    event.preventDefault();
    
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
    else{
      // Redirect to index page
      localStorage.setItem('linkTo', targetId);
      window.location.href = "/";
    }
  };
  
  return (
    <>
      <header className="fixed-nav">
        {/* <!-- ========== HEADING ==========--> */}
        <Navbar expand="lg" variant="light">
          <div className="nav-top">
            <Container>
              <div className="nav-container">
                <div className="logo">
                  <img
                    width="70"
                    height="70"
                    src="./images/svg/sb-icon.svg"
                    alt="logo"
                  />
                  <img
                    width="147"
                    height="70"
                    src="./images/svg/sb-logo.svg"
                    alt="logo"
                  />
                </div>

                <Navbar.Toggle aria-controls="navbarSupportedContent" />
                <div className="nav-container desktop">
                  {showNavList && (
                  <ul className="nav-list" style={{display:"none"}}>
                    <li className="nav-link">
                      <Link className="item-link" to="/Professionals">
                        Professionals
                      </Link>
                    </li>
                    <li className="nav-link">
                      <Link className="item-link" to="/CollegeStudents">
                        College Students
                      </Link>
                    </li>
                    <li className="nav-link">
                      <NavDropdown title="Kids" id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to="/Class5th">Class 5th onwards</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Class7th">Class 7th onwards</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Class10th">Class 10th onwards</NavDropdown.Item>
                      </NavDropdown>
                    </li>
                  </ul>
                  )}
                  <div className="search position-relative">
                    <form>
                   
                      <input
                        type="search"
                        placeholder="Search"
                        id="searchInput"
                        ref={searchInputRef}
                        autoComplete="off"
                        onClick={handleSearchInputClick} // Click event to hide nav list
                        onBlur={handleSearchInputBlur}
                        onChange={(e) => {
                          searchHandler(e);
                        }}
                        style={{ 
                          width: isInputFocused ? "470px" : "217px", // Initially hidden
                          maxWidth: "470px",
                          opacity: isInputFocused ? "1" : "1", // Initially hidden
                          transition: "width 0.3s, opacity 0.3s",
                        }}
                      />
                      {!isSearch ? (
                        <span type="submit" aria-label="submit">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </span>
                      ) : null}
                    </form>
                    {isSearch ? (
                      <>
                        {searchData && searchData.length ? (
                          <>
                            <ul
                              ref={targetRef}
                              style={{ top: "100%", left: 0 }}
                              className="position-absolute list-group cursor-pointer w-100 bg-white search-result"
                            >
                              {searchData &&
                                searchData.map((item, index) => (
                                  <li
                                    key={index}
                                    className="list-group-item d-flex align-items-start"
                                    onClick={() => handleSearchClick(item)}
                                  >
                                    <div className="course-img">
                                      <img
                                        src={item?.CourseImageURL200H}
                                        alt="course image"
                                      />
                                    </div>
                                    {item?.CourseTitle}
                                  </li>
                                ))}
                            </ul>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>

                </div>
                <div className="buttons-container">
                  <div className="sbtnbx">
                    <ToggleButton isOn={isUSDCurrency} toggle={toggleCurrency} />
                  </div>
                  {Username?
                    (
                      <button
                        className="buy circle"
                        type="button"
                        onClick={handleCartShow}
                      >
                        <FontAwesomeIcon icon={faShoppingCart} />
                        {cartItemCount > 0 && <span className="cart-counter">{cartItemCount}</span>}
                      </button>
                    ) : (<></>)
                  }
                  {Username == null ? (
                    <><button
                      className="user circle"
                      type="button"
                      onClick={handleSignInShow}
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </button><button
                      className="btn primary hideon-mobile"
                      type="button"
                      onClick={handleSignUpShow}
                    >
                        Sign up
                      </button></>
                  ) : 

                    (
                      <>
                        <NavDropdown
                          title={
                            <div className="user-circle-dropdown">
                              <div className="user-circle">
                                {Username.charAt(0)}
                              </div>
                            </div>
                          }
                          id="basic-nav-dropdown"
                        >
                          <NavDropdown.Item>
                            {Username}
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/my-profile">
                            My Profile
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} onClick={(e) => handleAnchorClick(e, 'mycoursestart')}>
                            My Courses
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={DoLogout}>
                            Logout
                          </NavDropdown.Item>
                        </NavDropdown>
                      </>
                    )
                  }
                </div>

                <Navbar.Collapse id="navbarSupportedContent">
                  <div className="navContent">
                    <ul className="nav-list" style={{display:"none"}}>
                      <li className="nav-link">
                        <Link className="item-link" to="/Professionals">
                          Professionals
                        </Link>
                      </li>
                      <li className="nav-link">
                        <Link className="item-link" to="/CollegeStudents">
                          College Students
                        </Link>
                      </li>
                      <li className="nav-link">
                        <NavDropdown title="Kids" id="basic-nav-dropdown">
                          <NavDropdown.Item as={Link} to="/Class5th">Class 5th onwards</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/Class7th">Class 7th onwards</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/Class10th">Class 10th onwards</NavDropdown.Item>
                        </NavDropdown>
                      </li>
                    </ul>
                    <div className="search position-relative">
                      <form>
                        <input
                          type="search"
                          placeholder="Search"
                          id="searchInputSignup"
                          ref={searchInputRef}
                          autoComplete="off"
                          onChange={(e) => {
                            searchHandler(e);
                          }}
                        />
                        {!isSearch ? (
                          <span type="submit" aria-label="submit">
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                          </span>
                        ) : null}
                      </form>
                      {isSearch ? (
                        <>
                          {searchData && searchData.length ? (
                            <>
                              <ul
                                ref={targetRef}
                                style={{ top: "100%", left: 0 }}
                                className="position-absolute list-group cursor-pointer w-100 bg-white search-result"
                              >
                                {searchData &&
                                  searchData.map((item, index) => (
                                    <li
                                      key={index}
                                      className="list-group-item d-flex align-items-start"
                                      onClick={() => handleSearchClick(item)}
                                    >
                                      <div className="course-img">
                                        <img
                                          src={item?.CourseImageURL200H}
                                          alt="course image"
                                        />
                                      </div>
                                      {item?.CourseTitle}
                                    </li>
                                  ))}
                              </ul>
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="closeNav">
                    <Navbar.Toggle className="border-0">
                      <FontAwesomeIcon className="text-white" icon={faTimes} />
                    </Navbar.Toggle>
                  </div>
                </Navbar.Collapse>
              </div>
            </Container>
          </div>

          <div className="nav-bottom">
            <div className="container">
              <div className="nav-container">
                <ul className="nav-list">
                  <li className="nav-link">
                    <a className="item-link" href="/">
                      <i className="far fa-home-code">
                        <FontAwesomeIcon icon={faHome} />
                      </i>
                    </a>
                  </li>
                  <li className="nav-link">
                    <a className="item-link" href="#ourcourse" onClick={(e) => handleAnchorClick(e, 'ourcourse')}>Courses</a>
                  </li>
                  <li className="nav-link">
                      <NavDropdown title="App" id="basic-nav-dropdown">
                        <NavDropdown.Item href="https://skillbakery.com/mobileapp.apk" download>
                          <img src="./images/play-store.svg" className="" /> Google Play Store
                        </NavDropdown.Item>
                        {/* <NavDropdown.Item as={Link} to=""><img src='./images/app-store.svg' className='' /> App Store</NavDropdown.Item> */}
                      </NavDropdown>
                    </li>
                  <li className="nav-link">
                    <a className="item-link" href="https://blog.skillbakery.com/" target="_blank" rel="noreferrer">
                      Blog
                    </a>
                  </li>
                  <li className="nav-link">
                    <Link className="item-link" to="/learning-path">
                      Learning Path
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Navbar>
      </header>

      <SignInModal show={showSignIn} handleClose={handleSignInClose} handleCreateButtonClicked={handleSignInCreateButton} />
      <SignUpModal show={showSignUp} handleClose={handleSignUpClose} handleLoginButtonClicked={handleSignUpLoginButton} />
      <CartDetailsModal
        show={showCart}
        handleClose={handleCartClose}
        removeFromCart={removeFromCart}
        cartItems={cartItems}
        isUSDCurrency={isUSDCurrency}
        getCartItems={getCartItems} 
        emptyCart={emptyCart}/>

      {courseData ? (
        <CourseDetailsModal
          courseData={courseData}
          show={showCourseInfoModal}
          handleClose={handleCourseInfoModalClose}
        />
      ) : null}
    </>
  );
}

export default Header;
