// CollegeStudentsPage.js
import React, { useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Testimonials from "../Testimonials";
import Contact from "../Contact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowRight,
  faClockFour,
  faFileVideo,
  faLaptopCode,
  faPlay,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";

const CollegeStudents = (toggleCurrency) => {
    const headerStyle = {
        position: 'relative',
        borderBottom: '1px solid rgba(255, 255, 255, 0.8)',
      };
    
      const getCartItems = () => {
        // Your implementation to get cart items
      };
    
  return (
    <div className="homepage-wrapper">
       {/* <video id="background-video" autoPlay loop muted>
        <source src="./images/giphy-new.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <img src="./images/giphy-new.gif" id="background-video"></img>
      <Header getCartItems={getCartItems}/>
      <main>
        <div className="top-box"></div>
        <section className="pb-115">
            <div className="container">
                <div className="row g-0">
                    <div className="col-lg-7 col-12">
                        <div className="pro-wb">
                          <h4>Skillbakery Studio: <span>Shaping</span> Future-<span>Ready</span> Careers</h4>
                          <h6 stye={{MaxWidth:'430px'}}>Empowering B.Tech, B.C.A, and Software Engineering Students with Practical Skills</h6>
                          <ul>
                              <li>Tailored Learning for Academic Excellence</li>
                              <li>Industry-Focused Learning</li>
                              <li>Future-Ready Education</li>
                              <li>Hands-On Projects</li>
                              <li>Expert Faculty</li>
                          </ul>
                          <button class="btn primary hideon-mobile px-4" type="button">Explore our courses</button>
                        </div>
                    </div>
                    <div className="col-lg-5 col-12">
                    <figure className="mb-0 profigure">
                        <img src="./images/cs-banner.png" className="proimg"/>
                    </figure>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col text-center section-top-head">
                      <h2 className="section-head pro">Features</h2>
                      <p className="text-center pro">Explore Our Professional Features</p>
                    </div>
                </div>
                <div className="row g-4">
                    <div className="col-lg-4 col-md-4 col-12  professionals">
                      <div className="subscription__card">
                        <div>
                          <figure className="mb-0 d-flex" style={{justifyContent:'center', alignItems:'center'}}>
                            <img src="./images/adv-course.svg"/>
                          </figure>
                        </div>
                        <div>
                          <h4 className="section-head pro">Basic </h4>
                          <ul className="sclist">
                              <li>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
                              <span>6 Months Training Certificate</span>
                              </li>
                              <li>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
                              <span>Project Submission</span>
                              </li>
                              <li>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
                              <span>Problem Solving via Email</span>
                              </li>
                              <li style={{visibility:'hidden'}}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
                              <span>Project Submission</span>
                              </li>
                          </ul>
                          <p className="mb-0 pro pptitle csp">5,000 INR</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 professionals">
                      <div className="subscription__card">
                      <div>
                          <figure className="mb-0 d-flex" style={{justifyContent:'center', alignItems:'center'}}>
                            <img src="./images/success.svg"/>
                          </figure>
                        </div>
                        <div>
                          <h4 className="section-head pro">Pro</h4>
                          <ul className="sclist">
                            <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
                            <span>6 Month Training certificate</span>
                            </li>
                            <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
                            <span>Project Submission</span>
                            </li>
                            <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
                            <span>Problem Solving via Email</span>
                            </li>
                            <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
                            <span>Helping Project Selection Web Based only</span>
                            </li>
                          </ul>
                          <p className="mb-0 pro pptitle">10,000 INR</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 professionals">
                      <div className="subscription__card">
                        <div>
                          <figure className="mb-0 d-flex" style={{justifyContent:'center', alignItems:'center'}}>
                            <img src="./images/resources.svg"/>
                          </figure>
                        </div>
                        
                        <div>
                        <h4 className="section-head pro">Expert</h4>
                          <ul className="sclist">
                              <li>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
                              <span>6 Month Training Certificate</span>
                              </li>
                              <li>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
                              <span>Course Video according to your preference</span>
                              </li>
                              <li>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
                              <span>Project Submission</span>
                              </li>
                              <li>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 p-0.5 dark:text-indigo-200 h-5 w-5 text-indigo-400"><polyline points="20 6 9 17 4 12"></polyline></svg>
                              <span>Problem Solving via Email</span>
                              </li>
                          </ul>
                          <p className="mb-0 pro pptitle">25,000 INR</p>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </section>
      </main>
      <Contact />
      <Footer/>
    </div>
  );
};

export default CollegeStudents;